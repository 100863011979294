/* eslint-disable react/prop-types */
function Chart({ data }) {
  return Array.isArray(data) ? (
    <div>
      {data.map((item, index) => {
        const keys = Object.keys(item);
        const valueOne = parseInt(item[keys[0]]);
        const widthOneStyle = { width: `${valueOne}%` };

        return (
          <div key={index} className="my-2">
            <div className="flex justify-between">
              <span>
                {keys[0]} : {item[keys[0]]}
              </span>
              <span>
                {keys[1]}: {item[keys[1]]}
              </span>
            </div>
            <div className={"h-3 bg-yellow-500"}>
              <div style={widthOneStyle} className={"h-3 bg-blue-500"} />
            </div>
          </div>
        );
      })}
    </div>
  ) : (
    <div className="text-center m-4">
      <p>No Results</p>
    </div>
  );
}

export default Chart;
