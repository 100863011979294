import React from "react";
import ReactDOM from "react-dom/client";
import { Router } from "@/components/local";
import ErrorBoundary from "@/containers/error";
import * as Sentry from "@sentry/react";
import App from "./App.jsx";
import "./styles/index.css";

const isProduction = import.meta.env.VITE_APP_STAGE === "production";
const sentryDsn = import.meta.env.VITE_SENTRY_DSN;

if (isProduction) {
  Sentry.init({
    dsn: sentryDsn,
    integrations: [
      new Sentry.BrowserTracing({
        tracePropagationTargets: [
          "localhost",
          /^https:\/\/www\.kitabiandme\.com\//,
          /^https:\/\/api\.kitabiandme\.com\//
        ]
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false
      })
    ],
    tracesSampleRate: 0.75,
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0
  });
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <ErrorBoundary>
      <Router>
        <App />
      </Router>
    </ErrorBoundary>
  </React.StrictMode>
);
