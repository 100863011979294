/* eslint-disable react/prop-types */

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  useReactTable
} from "@/components/ui/table";
import { Card, CardContent } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Reload, ChevronLeft, ChevronRight } from "@/components/local/icons";
import { capitalize, formatDate, parse } from "@/utils";
import { useNavigate } from "@/components/utils";

function ViewContainer({ requestsData, refetch }) {
  const columns = [
    {
      header: "First Name",
      accessorKey: "firstname",
      cell: ({ row }) => capitalize(row.original.firstname)
    },
    {
      header: "Last Name",
      accessorKey: "lastname",
      cell: ({ row }) => capitalize(row.original.lastname)
    },
    {
      accessorKey: "dateOfBirth",
      header: "Date Of Birth",
      cell: ({ row }) => {
        return formatDate(parse(row.original.dateOfBirth, "yyyy-MM-dd", new Date()), "PPP");
      }
    },
    {
      accessorKey: "status",
      header: "Status",
      cell: ({ row }) => {
        return (
          <span className={row.original.status === "pending" ? "text-red-500" : "text-green-700"}>
            {capitalize(row.original.status)}
          </span>
        );
      }
    },
    {
      accessorKey: "createdAt",
      header: "Created On",
      cell: ({ row }) => {
        return formatDate(new Date(row.original.createdAt), "PPP");
      }
    }
  ];

  const navigate = useNavigate();
  const table = useReactTable({
    data: requestsData,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel()
  });

  return (
    <div>
      <div className="mb-2 py-3">
        <div className="flex items-center justify-between">
          <div className="text-xl font-medium">Requests</div>
          <div className="cursor-pointer transition-all ease-in-out duration-100">
            <Button variant="ghost" className="text-xs h-8" onClick={refetch}>
              <Reload className="h-3 w-4" />
              &nbsp;&nbsp;Reload
            </Button>
          </div>
        </div>
      </div>
      <Card>
        <CardContent className="mt-2">
          <Table>
            <TableHeader>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    return (
                      <TableHead key={header.id} className="font-semibold">
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </TableHead>
                    );
                  })}
                </TableRow>
              ))}
            </TableHeader>
            <TableBody>
              {table.getRowModel().rows?.length ? (
                table.getRowModel().rows.map((row) => (
                  <TableRow
                    key={row?.original?._id}
                    className="cursor-pointer hover:bg-muted/50"
                    onClick={() => {
                      navigate("/admin/dashboard/" + row?.original?._id);
                    }}
                  >
                    {row.getVisibleCells().map((cell) => (
                      <TableCell key={cell?.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={columns.length} className="h-24 text-center">
                    No results.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </CardContent>
      </Card>
      <div className="mt-2">
        <div className="flex items-center justify-between px-2">
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            Page {table.getState().pagination.pageIndex + 1} of {table.getPageCount()}
          </div>
          <div className="flex w-[100px] items-center justify-center text-sm font-medium">
            <div className="flex items-center space-x-2">
              <Button
                variant="outline"
                className="h-8 w-8 p-0 bg-white"
                onClick={() => table.previousPage()}
                disabled={!table.getCanPreviousPage()}
              >
                <span className="sr-only">Go to previous page</span>
                <ChevronLeft className="h-4 w-4" />
              </Button>
              <Button
                variant="outline"
                className="h-8 w-8 p-0 bg-white"
                onClick={() => table.nextPage()}
                disabled={!table.getCanNextPage()}
              >
                <span className="sr-only">Go to next page</span>
                <ChevronRight className="h-4 w-4" />
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewContainer;
