import safety from "@/helpers/safety";
import feathersClient from "@/helpers/feathers";

const requestService = feathersClient.service("requests");

export const createRequestThunk = async (payload) => {
  return await safety(requestService.create(payload));
};

export const findRequestsThunk = async (query) => {
  return await safety(requestService.find({ query }));
};

export const getRequestThunk = async (id) => {
  return await safety(requestService.get(id));
};

export const updateRequestThunk = async (id, payload) => {
  return await safety(requestService.patch(id, payload));
};

export const removeRequestThunk = async (id) => {
  return await safety(requestService.remove(id));
};
