import React from "react";
import ViewContainer from "@/containers/admin/view";
import useStore, { setLoadingSelector } from "@/store";
import { useToast } from "@/components/ui/use-toast";
import { findRequestsThunk } from "@/clients/request";

function DashboardPage() {
  const { toast } = useToast();
  const setLoading = useStore(setLoadingSelector);
  const [requestsData, setRequestsData] = React.useState([]);

  const fetchRequests = async () => {
    setLoading(true);
    const requestResponse = await findRequestsThunk(); //{ $sort: { createdAt: "-1" }, $limit: 25, $skip: 0 }
    if (requestResponse.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: requestResponse.error.message
      });
    } else {
      setRequestsData(requestResponse.data.data);
    }
    setLoading(false);
  };

  React.useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="px-4 py-2 w-full">
      <div className="mx-8">
        <ViewContainer requestsData={requestsData} refetch={fetchRequests} />
      </div>
    </div>
  );
}

export default DashboardPage;
