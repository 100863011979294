import safety from "@/helpers/safety";
import feathersClient from "@/helpers/feathers";

const NameService = feathersClient.service("names");

export const createNameThunk = async (payload) => {
  return await safety(NameService.create(payload));
};

export const findNamesThunk = async (query) => {
  return await safety(NameService.find({ query }));
};

export const getNameThunk = async (id) => {
  return await safety(NameService.get(id));
};

export const updateNameNameThunk = async (id, payload) => {
  return await safety(NameService.update(id, payload));
};

export const removeNameThunk = async (id) => {
  return await safety(NameService.remove(id));
};
