/* eslint-disable react/prop-types */
import { Reload, ChevronLeft, XCircle, CheckCircle2, History } from "@/components/local/icons";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Table, TableBody, TableCell, TableHead, TableHeader, TableRow } from "@/components/ui/table";
import { capitalize, formatDate, parseISO, parse } from "@/utils";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useNavigate } from "@/components/utils";
import { NameContent, DayContent, DateContent } from "./content";

function UpdateContainer({ trigger, refetch, updaters, upload, requestData, fulfillmentData }) {
  const navigate = useNavigate();
  return (
    <div className="grid gap-4">
      <div className="flex gap-16 items-center justify-between">
        <div>
          <Button
            variant="ghost"
            className="text-xs h-8"
            onClick={() => {
              navigate(-1);
            }}
          >
            <ChevronLeft className="h-4 w-4" />
            &nbsp;Go Back
          </Button>
        </div>
        <div>
          <Button variant="ghost" className="text-xs h-8" onClick={refetch}>
            <Reload className="h-3 w-4" />
            &nbsp;&nbsp;Reload
          </Button>
        </div>
      </div>
      <Card className="">
        <CardHeader>
          <CardTitle>
            <div className="flex items-center justify-between font-light text-xs text-gray-800">
              <div className="flex items-center justify-between">
                <div>Request ID : {requestData?._id} &nbsp; | &nbsp; </div>
                <div>
                  {requestData?.createdAt && formatDate(parseISO(requestData?.createdAt), "yyyy-MM-dd")}
                </div>
              </div>
              <div className="font-medium text-sm">
                Status :&nbsp;
                <span className={requestData?.status === "pending" ? "text-red-500" : "text-green-700"}>
                  {capitalize(requestData?.status)}
                </span>
              </div>
            </div>
          </CardTitle>
        </CardHeader>
        <CardContent>
          <div className="text-sm">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>First Name</TableHead>
                  <TableHead>Last Name</TableHead>
                  <TableHead>Email</TableHead>
                  <TableHead>Date Of Birth</TableHead>
                  <TableHead>Gender</TableHead>
                  <TableHead>City</TableHead>
                  <TableHead>State</TableHead>
                  <TableHead>Country</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                <TableRow key={requestData?._id}>
                  <TableCell>{capitalize(requestData?.firstname)}</TableCell>
                  <TableCell>{capitalize(requestData?.lastname)}</TableCell>
                  <TableCell>{requestData?.email}</TableCell>
                  <TableCell>
                    {requestData?.dateOfBirth &&
                      formatDate(parse(requestData?.dateOfBirth, "yyyy-MM-dd", new Date()), "PPP")}
                  </TableCell>
                  <TableCell>{capitalize(requestData?.gender)}</TableCell>
                  <TableCell>{capitalize(requestData?.city)}</TableCell>
                  <TableCell>{capitalize(requestData?.state)}</TableCell>
                  <TableCell>{capitalize(requestData?.country)}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </div>
        </CardContent>
      </Card>
      <Tabs defaultValue="name" className="w-full">
        <TabsList className="grid w-full grid-cols-3">
          <TabsTrigger value="name">Name</TabsTrigger>
          <TabsTrigger value="day">Day</TabsTrigger>
          <TabsTrigger value="date">Date</TabsTrigger>
        </TabsList>
        <TabsContent value="name">
          <NameContent content={fulfillmentData} update={updaters.name} upload={upload} />
        </TabsContent>
        <TabsContent value="day">
          <DayContent content={fulfillmentData} update={updaters.day} upload={upload} />
        </TabsContent>
        <TabsContent value="date">
          <DateContent content={fulfillmentData} update={updaters.date} upload={upload} />
        </TabsContent>
      </Tabs>

      <div className="my-10">
        {fulfillmentData &&
        fulfillmentData.name &&
        fulfillmentData.day &&
        fulfillmentData.date &&
        requestData ? (
          <div>
            <div className="flex gap-8 items-center justify-end">
              <div>
                <Button
                  variant="outline"
                  className="text-xs h-8 border-blue-600 text-blue-600 hover:text-blue-800"
                  onClick={() => {
                    navigate("/admin/dashboard/preview/" + fulfillmentData?._id);
                  }}
                >
                  <CheckCircle2 className="h-4 w-4" />
                  &nbsp; Approve
                </Button>
              </div>
              <div>
                <Button
                  variant="outline"
                  className="text-xs h-8  border-red-600 text-red-600 hover:text-red-800"
                >
                  <XCircle className="h-4 w-4" />
                  &nbsp; Decline
                </Button>
              </div>
            </div>
          </div>
        ) : (
          <div className="flex gap-8 items-center justify-start">
            <div>
              <Button
                variant="outline"
                className="text-xs h-8  border-yellow-600 text-yellow-600 hover:text-yellow-800"
                onClick={async () => {
                  await trigger({ event: "fulfillment", payload: requestData });
                }}
              >
                <History className="h-4 w-4" />
                &nbsp; Trigger Fulfillment
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default UpdateContainer;
