import { nanoid } from "nanoid";
export {
  format as formatDate,
  formatISO as formatISODate,
  parse,
  parseISO,
  getDate,
  getDay,
  getMonth,
  getYear,
  startOfDay
} from "date-fns";

export const replaceById = (id, obj, arr) => {
  const index = arr.findIndex((item) => item.id === id);
  if (index !== -1) arr[index] = obj;
  return arr;
};

export const getFromArray = (id, array) => {
  return array.find((item) => item.id === id) || null;
};

export const isImageDataUri = (uri) => {
  const dataUriRegex = /^data:([a-zA-Z0-9]+\/[a-zA-Z0-9.-]+)?(;base64)?,([a-zA-Z0-9!#$&-;=._~%\s]*)(#.*)?$/;
  const allowedMimeTypes = ["image/jpeg", "image/png", "image/gif", "image/svg+xml", "image/webp"];
  if (!dataUriRegex.test(uri)) {
    return false;
  }
  const matches = uri.match(dataUriRegex);
  const mimeType = matches[1];
  return allowedMimeTypes.includes(mimeType);
};

export const generateUploadable = (arr, keyPrefix) => {
  if (!Array.isArray(arr)) {
    return arr;
  }
  return arr.map((src) => ({
    src,
    key: keyPrefix ? `${keyPrefix}${nanoid()}.png` : `${nanoid()}.png`
  }));
};

export const isObjectEmpty = (obj) => {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false;
    }
  }
  return JSON.stringify(obj) === JSON.stringify({});
};

export const formatCamelCase = (input) => {
  const words = input.split(/(?=[A-Z])/);
  const formattedString = words.map((word) => word.charAt(0).toUpperCase() + word.slice(1)).join(" ");
  return formattedString;
};

export const sanitizeAndCapitalize = (string) => {
  if (typeof string === "string") {
    return string
      .replace(/_+/g, " ")
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  } else {
    return string;
  }
};

export const getUnique = (arr, comp) => {
  const unique = arr
    .map((e) => e[comp])
    .map((e, i, final) => final.indexOf(e) === i && i)
    .filter((e) => typeof e === "number" && arr[e])
    .map((e) => typeof e === "number" && arr[e]);

  return unique;
};

export const sanitizeString = (string) => {
  return string.replace(/\s/g, "").toLowerCase();
};

export const capitalize = (string) => {
  if (typeof string === "string") {
    return string
      .trim()
      .toLowerCase()
      .replace(/\w\S*/g, (w) => w.replace(/^\w/, (c) => c.toUpperCase()));
  } else {
    return string;
  }
};

export const filterOutArray = (filter, array) => {
  if (!Array.isArray(array)) {
    return array;
  } else {
    return array.filter((item) => item !== filter);
  }
};

export const generateId = nanoid;
