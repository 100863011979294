/* eslint-disable react/prop-types */
import { SpinnerFull } from "@/components/local/icons";

function Content({ children, loading }) {
  return (
    <div>
      <div className={loading ? "flex items-center justify-center min-h-content" : "hidden"}>
        <div className="text-center">
          <SpinnerFull className="h-20 w-20 animate-spin" />
          <p className="text-xs mt-1">Please Wait</p>
        </div>
      </div>
      <div className={loading ? "hidden" : "p-2 m-2 flex-col min-h-content"}>{children}</div>
    </div>
  );
}

export default Content;
