import safety from "@/helpers/safety";
import feathersClient from "@/helpers/feathers";

const DayService = feathersClient.service("days");

export const createDayThunk = async (payload) => {
  return await safety(DayService.create(payload));
};

export const findDaysThunk = async (query) => {
  return await safety(DayService.find({ query }));
};

export const getDayThunk = async (id) => {
  return await safety(DayService.get(id));
};

export const updateDayThunk = async (id, payload) => {
  return await safety(DayService.update(id, payload));
};

export const removeDayThunk = async (id) => {
  return await safety(DayService.remove(id));
};
