import safety from "@/helpers/safety";
import feathersClient, { feathersBufferClient } from "@/helpers/feathers";

const documentService = feathersBufferClient.service("fulfillments/document");

export const createDocumentThunk = async (payload) => {
  return await safety(documentService.create(payload));
};

const fulfillmentService = feathersClient.service("fulfillments");

export const createFulfillmentThunk = async (payload) => {
  return await safety(fulfillmentService.create(payload));
};

export const findFulfillmentsThunk = async (query) => {
  return await safety(fulfillmentService.find({ query }));
};

export const getFulfillmentThunk = async (id) => {
  return await safety(fulfillmentService.get(id));
};

export const updateFulfillmentThunk = async (id, payload) => {
  return await safety(fulfillmentService.patch(id, payload));
};

export const removeFulfillmentThunk = async (id) => {
  return await safety(fulfillmentService.remove(id));
};
