import React from "react";
import UpdateContainer from "@/containers/admin/update";
import { useParams, useNavigate } from "@/components/utils";
import useStore, { setLoadingSelector } from "@/store";
import { useToast } from "@/components/ui/use-toast";
import { findFulfillmentsThunk } from "@/clients/fulfillment";
import { getRequestThunk } from "@/clients/request";
import { updateNameNameThunk } from "@/clients/name";
import { updateDayThunk } from "@/clients/day";
import { updateDateThunk } from "@/clients/date";
import { createMediaStorageThunk } from "@/clients/storage";
import { createTriggerThunk } from "@/clients/triggers";

function DetailsPage() {
  const navigate = useNavigate();
  const { requestSlug } = useParams();
  const { toast } = useToast();
  const setLoading = useStore(setLoadingSelector);
  const [requestData, setRequestData] = React.useState(null);
  const [fulfillmentData, setFulfillmentData] = React.useState(null);

  const fetchRequest = async () => {
    setLoading(true);
    const response = await getRequestThunk(requestSlug);
    if (response.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.error.message
      });
    } else {
      setRequestData(response.data);
    }
    setLoading(false);
  };

  const fetchFulfillment = async () => {
    setLoading(true);
    const response = await findFulfillmentsThunk({ requestId: requestSlug });
    if (response.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.error.message
      });
    } else {
      const data = response.data?.data[0];
      if (data) {
        const fulfillmentData = {
          _id: data?._id,
          createdAt: data?.createdAt,
          day: data?.generic?.day,
          date: data?.generic?.date,
          name: data?.generic?.name,
          weather: data?.specific?.weather,
          astronomy: data?.specific?.astronomy
        };
        setFulfillmentData(fulfillmentData);
      } else {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: "No record found."
        });
      }
    }
    setLoading(false);
  };

  const updaters = {
    name: async ({ _id, ...payload }) => {
      setLoading(true);
      const response = await updateNameNameThunk(_id, payload);
      if (response.error) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.error.message
        });
      }
      setLoading(false);
      return response;
    },
    day: async ({ _id, ...payload }) => {
      setLoading(true);
      const response = await updateDayThunk(_id, payload);
      if (response.error) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.error.message
        });
      }
      setLoading(false);
      return response;
    },
    date: async ({ _id, ...payload }) => {
      setLoading(true);
      const response = await updateDateThunk(_id, payload);
      if (response.error) {
        toast({
          variant: "destructive",
          title: "Uh oh! Something went wrong.",
          description: response.error.message
        });
      }
      setLoading(false);
      return response;
    }
  };

  const upload = async (payload) => {
    const response = await createMediaStorageThunk(payload);
    if (response.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.error.message
      });
    }
    return response;
  };

  const trigger = async (payload) => {
    const response = await createTriggerThunk(payload);
    if (response.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.error.message
      });
    } else {
      toast({
        variant: "constructive",
        title: "Success! No issues encountered.",
        description: "Please await completion of the task for a brief duration."
      });
    }
    return response;
  };

  React.useEffect(() => {
    if (requestSlug) {
      fetchRequest();
      fetchFulfillment();
    } else {
      navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestSlug]);

  return (
    <div className="px-4 py-2 w-full">
      <div className="mx-8">
        <UpdateContainer
          trigger={trigger}
          requestData={requestData}
          updaters={updaters}
          upload={upload}
          fulfillmentData={fulfillmentData}
          refetch={fetchFulfillment}
        />
      </div>
    </div>
  );
}

export default DetailsPage;
