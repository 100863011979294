import safety from "@/helpers/safety";
import feathersClient from "@/helpers/feathers";

const DateService = feathersClient.service("dates");

export const createDateThunk = async (payload) => {
  return await safety(DateService.create(payload));
};

export const findDatesThunk = async (query) => {
  return await safety(DateService.find({ query }));
};

export const getDateThunk = async (id) => {
  return await safety(DateService.get(id));
};

export const updateDateThunk = async (id, payload) => {
  return await safety(DateService.update(id, payload));
};

export const removeDateThunk = async (id) => {
  return await safety(DateService.remove(id));
};
