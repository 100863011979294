/* eslint-disable react/prop-types */

const getRandomColor = () => {
  const letters = "0123456789ABCDEF";
  let color = "#";
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

const generateWordCloudData = ({ primaryName, secondaryNames }) => {
  if (Array.isArray(secondaryNames)) {
    const secondaryData = secondaryNames.map((text) => {
      const importance = Math.random() * 2;
      return { text, importance, type: "secondary" };
    });
    if (Array.isArray(secondaryData) && primaryName) {
      const middleIndex = Math.floor(secondaryData.length / 2);
      const primaryData = [
        ...secondaryData.slice(0, middleIndex),
        { text: primaryName, importance: 4, type: "primary" },
        ...secondaryData.slice(middleIndex)
      ];
      return primaryData;
    } else {
      return secondaryData;
    }
  } else return secondaryNames;
};

function WordCloud({ primaryName, secondaryNames }) {
  const words = generateWordCloudData({ primaryName, secondaryNames });

  return (
    <div className="w-full h-full flex flex-wrap justify-center items-center">
      {Array.isArray(words) ? (
        <div>
          {words.map((word, index) => (
            <span
              key={index}
              style={{
                fontSize:
                  word.type === "primary"
                    ? `${word.importance}rem`
                    : `${Math.abs(3 - word.importance) * 0.5}rem`,
                color: getRandomColor(),
                transform: `rotate(${index % 2 === 0 ? "" : "-"}${Math.floor(Math.random() * 61) - 30}deg)`
              }}
            >
              {word.text}
            </span>
          ))}
        </div>
      ) : (
        <div className="text-center m-4">
          <p>No Results</p>
        </div>
      )}
    </div>
  );
}

export default WordCloud;
