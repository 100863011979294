/* eslint-disable react/prop-types */
import React from "react";
import PreviewContainer from "@/containers/admin/preview";
import { Reload, ChevronLeft, Download, Send } from "@/components/local/icons";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Button } from "@/components/ui/button";
import { useParams, useNavigate } from "@/components/utils";
import useStore, { setLoadingSelector } from "@/store";
import { useToast } from "@/components/ui/use-toast";
import { getFulfillmentThunk, createDocumentThunk } from "@/clients/fulfillment";
import { createTriggerThunk } from "@/clients/triggers";

function PreviewPage() {
  const navigate = useNavigate();
  const { fulfillmentSlug } = useParams();
  const { toast } = useToast();
  const setLoading = useStore(setLoadingSelector);
  const [fulfillmentData, setFulfillmentData] = React.useState(null);

  const fetchFulfillment = async () => {
    setLoading(true);
    const response = await getFulfillmentThunk(fulfillmentSlug);
    if (response.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.error.message
      });
    } else {
      const fulfillmentData = {
        _id: response.data?._id,
        createdAt: response.data?.createdAt,
        request: response.data?.request,
        day: response.data?.generic?.day,
        date: response.data?.generic?.date,
        name: response.data?.generic?.name,
        weather: response.data?.specific?.weather,
        astronomy: response.data?.specific?.astronomy
      };
      setFulfillmentData(fulfillmentData);
    }
    setLoading(false);
  };

  const download = async () => {
    setLoading(true);
    const response = await createDocumentThunk({ id: fulfillmentSlug, template: "test" });
    if (response.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.error.message
      });
    } else {
      const documentName = fulfillmentData?.request
        ? `${fulfillmentData?.request?.firstname}_${fulfillmentData?.request?.lastname}`
        : "preview.pdf";
      const blob = new Blob([response.data], { type: "application/pdf" });
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = documentName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(link.href);
    }
    setLoading(false);
    return response;
  };

  const sendEmail = async () => {
    setLoading(true);
    const response = await createTriggerThunk({ event: "email", payload: fulfillmentData.request });
    if (response.error) {
      toast({
        variant: "destructive",
        title: "Uh oh! Something went wrong.",
        description: response.error.message
      });
    } else {
      toast({
        variant: "constructive",
        title: "Success! No issues encountered.",
        description: "Fulfillment data sent as an attachment via email."
      });
    }
    setLoading(false);
  };

  React.useEffect(() => {
    if (fulfillmentSlug) fetchFulfillment();
    else navigate("/");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfillmentSlug]);

  return (
    <div className="px-4 py-2 w-full">
      <div className="mx-8">
        <div className="grid gap-4">
          <div className="flex gap-16 items-center justify-between">
            <div>
              <Button
                variant="ghost"
                className="text-xs h-8"
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ChevronLeft className="h-4 w-4" />
                &nbsp;Go Back
              </Button>
            </div>
            <div>
              <Button variant="ghost" className="text-xs h-8" onClick={fetchFulfillment}>
                <Reload className="h-3 w-4" />
                &nbsp;&nbsp;Reload
              </Button>
            </div>
          </div>
          <div className="px-4 py-2">
            <div className="flex justify-between gap-4 items-center">
              <div>
                <Select>
                  <SelectTrigger className="border-black h-8">
                    <SelectValue placeholder="Select Template" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="templateOne">Template One</SelectItem>
                    <SelectItem value="templateTwo">Template Two</SelectItem>
                  </SelectContent>
                </Select>
              </div>
              <div className="flex justify-evenly gap-8 items-center">
                <div>
                  <Button variant="outline" className="h-8 border-black" onClick={download}>
                    <Download className="h-3 w-4" />
                    &nbsp;Download
                  </Button>
                </div>
                <div>
                  <Button
                    variant="outline"
                    className="h-8 border-blue-600 text-blue-600 hover:text-blue-800"
                    onClick={sendEmail}
                  >
                    <Send className="h-3 w-3" />
                    &nbsp;Send Email
                  </Button>
                </div>
              </div>
            </div>
          </div>
          <div className="px-4 py-2">
            <PreviewContainer data={fulfillmentData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default PreviewPage;
