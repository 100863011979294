/* eslint-disable react/prop-types */
import React from "react";
import { capitalize } from "@/utils";
import { Card, CardContent } from "@/components/ui/card";
import WordCloud from "@/components/local/wordCloud";
import Chart from "@/components/local/chart";

function PreviewContainer({ data }) {
  const [content, setContent] = React.useState({
    wordCloud: null,
    consumer: null,
    name: null,
    day: null,
    date: null
  });

  React.useEffect(() => {
    if (data) {
      setContent((prevData) => {
        return {
          ...prevData,
          consumer: {
            firstname: data?.request?.firstname,
            lastname: data?.request?.lastname
          },
          wordCloud: {
            primaryName: data?.name?.firstname,
            secondaryNames: Object.values(data?.name?.equivalents) || []
          },
          name: data?.name,
          day: data?.day,
          date: data?.date
        };
      });
    }
  }, [data]);
  return (
    <div id="main-container-pdf">
      {typeof data === "object" ? (
        <div className="flex flex-col gap-4 justify-center items-center">
          <Card className="sub-container-pdf min-h-[650px] w-[1300px] flex justify-center items-center">
            <CardContent className="py-2 px-4">
              <div className="grid grid-cols-12 gap-2">
                <div className="col-span-5 justify-self-center place-self-center">
                  <div>
                    <p className="text-6xl">
                      {capitalize(content?.consumer?.firstname)}&nbsp;
                      {capitalize(content?.consumer?.lastname)}
                    </p>
                  </div>
                </div>
                <div className="col-span-7 justify-self-center place-self-center">
                  <WordCloud
                    primaryName={capitalize(content?.wordCloud?.primaryName)}
                    secondaryNames={content?.wordCloud?.secondaryNames}
                  />
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="my-1">
                      <p className="text-md text-gray-500">Name :</p>
                      <p className="text-lg">
                        {capitalize(content?.consumer?.firstname)}&nbsp;
                        {capitalize(content?.consumer?.lastname)}
                      </p>
                    </div>
                    <div className="my-1">
                      <p className="text-md text-gray-500">Gender :</p>
                      <p className="text-lg">{capitalize(content?.name?.gender)}</p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="my-1">
                      <p className="text-md text-gray-500">Usage :</p>
                      <p className="text-lg">{content?.name?.usage?.join(" , ")}</p>
                    </div>
                    <div className="my-1">
                      <p className="text-md text-gray-500">Pronounciation :</p>
                      <p className="text-lg">{content?.name?.pronounced}</p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="my-1">
                      <p className="text-md text-gray-500">Name Root :</p>
                      <p className="text-lg">{content?.name?.nameRoot}</p>
                    </div>
                    <div className="my-1">
                      <p className="text-md text-gray-500">Origin :</p>
                      <p className="text-lg">{capitalize(content?.name?.origin)}</p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Meaning :</p>
                  <p className="text-lg">{content?.name?.meaning}</p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Equivalents :</p>
                  <p className="text-lg">
                    {typeof content?.name?.equivalents === "object" &&
                      Object.entries(content?.name?.equivalents)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(" , ")}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Diminutives :</p>
                  <p className="text-lg">
                    {typeof content?.name?.diminutives === "object" &&
                      Object.entries(content?.name?.diminutives)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(" , ")}
                  </p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Extras :</p>
                  <p className="text-lg">
                    {typeof content?.name?.extras === "object" &&
                      Object.entries(content?.name?.extras)
                        .map(([key, value]) => `${key}: ${value}`)
                        .join(" , ")}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-2 gap-4">
                    <div className="my-1">
                      <div className="flex flex-col gap-4">
                        <div className="my-1">
                          <p className="text-md text-gray-500">Popular Opinion :</p>
                          <p className="text-lg">{content?.name?.popularOpinion?.join(" , ")}</p>
                        </div>
                        <div className="my-1">
                          <p className="text-md text-gray-500">Chakra Number :</p>
                          <p className="text-lg">{content?.name?.chakraNumber}</p>
                        </div>
                        <div className="my-1">
                          <p className="text-md text-gray-500">Chakra Number Description :</p>
                          <p className="text-lg">{content?.name?.chakraNumberDescription}</p>
                        </div>
                        <div className="my-1">
                          <p className="text-md text-gray-500">Color :</p>
                          <p className="text-lg">{content?.name?.color}</p>
                        </div>
                        <div className="my-1">
                          <p className="text-md text-gray-500">Color Description :</p>
                          <p className="text-lg">{content?.name?.colorDescription}</p>
                        </div>
                      </div>
                    </div>
                    <div className="my-1">
                      <p className="text-md text-gray-500">Ratings :</p>
                      <Chart data={content?.name?.rating} />
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Namesakes :</p>
                  <p className="text-lg">{content?.name?.namesakes?.join(" , ")}</p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Use In Other Languages :</p>
                  <p className="text-lg">
                    {typeof content?.name?.useInLanguages === "object" &&
                      Object.entries(content?.name?.useInLanguages)
                        .map(([key, value]) => `${key}: ${value.join(" , ")}`)
                        .join(" , ")}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Popular Songs :</p>
                  <p className="text-lg">
                    {typeof content?.name?.popularSongs === "object" &&
                      Object.entries(content?.name?.popularSongs)
                        .map(([key, value]) => `${key}: ${value.join(" , ")}`)
                        .join(" , ")}
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Popular People :</p>
                  <p className="text-lg">{content?.name?.popularPeople?.join(" , ")}</p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Behind The Name</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Popularity Map :</p>
                  <p className="text-lg">
                    <img
                      src={content?.name?.media}
                      loading="lazy"
                      className="w-auto max-w-[1000px] h-auto max-h-[1000px] my-2 bg-auto"
                    />
                  </p>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">On This Day</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">Events :</p>
                  <div>
                    {Array.isArray(content?.day?.general?.events) && (
                      <div>
                        {content?.day?.general?.events?.map((item, index) => {
                          return (
                            <div className="flex gap-4 justify-between" key={index}>
                              <div>
                                <p>
                                  <span className="text-md text-gray-500">In Year :</span>{" "}
                                  <span className="text-lg">{item.year}</span>
                                </p>
                                <p className="text-lg">{item.description}</p>
                              </div>
                              <div>
                                {Array.isArray(item.media) &&
                                  item.media.map((src, index) => (
                                    <img
                                      key={index}
                                      src={src}
                                      loading="lazy"
                                      className="w-auto max-w-[300px] h-auto max-h-[300px] my-2 bg-auto"
                                    />
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Box Office</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">All time :</p>
                  <div>
                    {Array.isArray(content?.date?.trends?.movies) && (
                      <div>
                        {content?.date?.trends?.movies?.map((item, index) => {
                          return (
                            <div className="flex gap-4 justify-between mb-5" key={index}>
                              <div>
                                <p>
                                  <span className="text-md text-gray-500">Rank:</span>{" "}
                                  <span className="text-lg">{item.rank}</span>
                                </p>
                                <p>
                                  <span className="text-md text-gray-500">Title:</span>{" "}
                                  <span className="text-lg">{item.title}</span>
                                </p>
                                <p>
                                  <span className="text-md text-gray-500">Worldwide:</span>{" "}
                                  <span className="text-lg">{item.worldwide}</span>
                                </p>
                              </div>
                              <div>
                                {Array.isArray(item.media) &&
                                  item.media.map((src, index) => (
                                    <img
                                      key={index}
                                      src={src}
                                      loading="lazy"
                                      className="w-auto max-w-[100px] h-auto max-h-[100px] my-2 bg-auto"
                                    />
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">Billboards</p>
                </div>
                <div className="my-1">
                  <p className="text-md text-gray-500">All time :</p>
                  <div>
                    {Array.isArray(content?.date?.trends?.music) && (
                      <div>
                        {content?.date?.trends?.music?.map((item, index) => {
                          return (
                            <div className="flex gap-4 justify-between mb-5" key={index}>
                              <div>
                                <p>
                                  <span className="text-md text-gray-500">Rank:</span>{" "}
                                  <span className="text-lg">{item.rank}</span>
                                </p>
                                <p>
                                  <span className="text-md text-gray-500">Title:</span>{" "}
                                  <span className="text-lg">{item.title}</span>
                                </p>
                                <p>
                                  <span className="text-md text-gray-500">Artist:</span>{" "}
                                  <span className="text-lg">{item.artist}</span>
                                </p>
                              </div>
                              <div>
                                {Array.isArray(item.media) &&
                                  item.media.map((src, index) => (
                                    <img
                                      key={index}
                                      src={src}
                                      loading="lazy"
                                      className="w-auto max-w-[100px] h-auto max-h-[100px] my-2 bg-auto"
                                    />
                                  ))}
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">World Counts</p>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-4">
                    <div>
                      <p className="text-md text-gray-500">Current Population :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.current}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Births Today :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.birth}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Population Of Europe :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.europe}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Population Of North America :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.northAmerica}</p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-4">
                    <div>
                      <p className="text-md text-gray-500">Population Of Africa :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.africa}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Population Of Antarctica :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.antarctica}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Population Of Asia :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.asia}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Population Of South America :</p>
                      <p className="text-lg">{content?.date?.statistics?.population?.southAmerica}</p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-4">
                    <div>
                      <p className="text-md text-gray-500">World Average Temperature :</p>
                      <p className="text-lg">
                        {content?.date?.statistics?.economy?.worldAverageTemperature} &#176;C
                      </p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">CO2 Emitted :</p>
                      <p className="text-lg">{content?.date?.statistics?.economy?.CO2Emitted}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Gross World Product :</p>
                      <p className="text-lg">&#36;{content?.date?.statistics?.economy?.grossWorldProduct}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Ad Spend :</p>
                      <p className="text-lg">&#36;{content?.date?.statistics?.economy?.adSpend}</p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-4">
                    <div>
                      <p className="text-md text-gray-500">Consumers :</p>
                      <p className="text-lg">{content?.date?.statistics?.economy?.consumers}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Chemicals Produced :</p>
                      <p className="text-lg">{content?.date?.statistics?.economy?.chemicalsProduced} Tons</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Energy Used :</p>
                      <p className="text-lg">{content?.date?.statistics?.economy?.energyUsed} TJ</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500">Global Consumer Spending :</p>
                      <p className="text-lg">
                        &#36;{content?.date?.statistics?.economy?.globalConsumerSpending}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-4">
                    <div>
                      <p className="text-md text-gray-500"> Replanted Forests :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.replantedForests}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500"> Forests Cut Down :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.forestsCutDown}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500"> Fresh Water Used :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.freshWaterUsed} Tons</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500"> Melted Ice :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.meltedIce} Tons</p>
                    </div>
                  </div>
                </div>
                <div className="my-1">
                  <div className="grid grid-cols-4">
                    <div>
                      <p className="text-md text-gray-500"> People Needing Water :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.peopleNeedingWater}</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500"> Rise In Sea Level :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.riseInSeaLevel} cm</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500"> Water Consumed :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.waterConsumed} Tons</p>
                    </div>
                    <div>
                      <p className="text-md text-gray-500"> Wild Forest Left :</p>
                      <p className="text-lg">{content?.date?.statistics?.challenges?.wildForestLeft} %</p>
                    </div>
                  </div>
                </div>
              </div>
            </CardContent>
          </Card>
          <Card className="sub-container-pdf min-h-[650px] w-[1300px]">
            <CardContent className="py-2 px-4">
              <div className="flex flex-col px-6 py-6 gap-4">
                <div className="my-3">
                  <p className="text-3xl">News Papers</p>
                </div>
                <div className="my-1">
                  {Array.isArray(content?.date?.pages?.newspaper) &&
                    content?.date?.pages?.newspaper?.map((item, index) => (
                      <div key={index}>
                        <p>{item.category}</p>
                        <div className="flex flex-row overflow-x-auto">
                          {Array.isArray(item?.media) &&
                            item?.media?.map((page, idx) => (
                              <div key={idx}>
                                <div>
                                  <img
                                    src={page.src}
                                    loading="lazy"
                                    className="w-auto max-w-[100px] h-auto max-h-[100px] my-2 bg-auto"
                                  />
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </CardContent>
          </Card>
        </div>
      ) : (
        <div className="text-center m-4">
          <p>No Results</p>
        </div>
      )}
    </div>
  );
}

export default PreviewContainer;
