import React from "react";
import CreateContainer from "@/containers/request/create";
import { createRequestThunk } from "@/clients/request";
import { formatDate } from "@/utils";

function RequestPage() {
  const [status, setStatus] = React.useState(null);
  const createRequest = async (payload) => {
    setStatus("loading");
    payload.dateOfBirth = formatDate(payload.dateOfBirth, "yyyy-MM-dd");
    const response = await createRequestThunk(payload);
    if (response.error) {
      setStatus("errored");
    } else {
      setStatus("success");
    }
  };
  return (
    <div className="max-w-xl ml-auto mr-auto p-2">
      <CreateContainer createRequest={createRequest} status={status} />
    </div>
  );
}

export default RequestPage;
