import { useLocation } from "@/components/utils";
import { Routes, Route } from "@/components/local";
import { General as GeneralLayout } from "@/layouts";
import LandingPage from "@/pages/landing";
import SigninPage from "@/pages/user/signin";
import RequestPage from "@/pages/request";
import DashboardPage from "@/pages/admin/dashboard";
import DetailsPage from "@/pages/admin/details";
import PreviewPage from "@/pages/admin/preview";
import NotFoundPage from "@/pages/404";
import PublicRoutes from "@/routes/public";
import AdminRoutes from "@/routes/admin";

function App() {
  const location = useLocation();
  return (
    <Routes location={location}>
      <Route element={<GeneralLayout />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="/request" element={<RequestPage />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>

      <Route element={<PublicRoutes />}>
        <Route element={<GeneralLayout />}>
          <Route path="/signin" element={<SigninPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>

      <Route element={<AdminRoutes />}>
        <Route element={<GeneralLayout />}>
          <Route path="/admin/dashboard" element={<DashboardPage />} />
          <Route path="/admin/dashboard/:requestSlug" element={<DetailsPage />} />
          <Route path="/admin/dashboard/preview/:fulfillmentSlug" element={<PreviewPage />} />
          <Route path="*" element={<NotFoundPage />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
