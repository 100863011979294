/* eslint-disable react/prop-types */
import { Button } from "@/components/ui/button";
import { Spinner } from "@/components/local/icons";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Input } from "@/components/ui/input";
import { useForm, validatorResolver, validator } from "@/components/utils";
import { Card, CardContent, CardHeader, CardTitle } from "@/components/ui/card";

function LoginUser({ loginUser, status }) {
  const form = useForm({
    resolver: validatorResolver(
      validator.object({
        email: validator.string({
          required_error: "An email is required."
        }),
        password: validator.string({
          required_error: "A password is required."
        })
      })
    )
  });

  return (
    <Card className="p-2">
      <CardHeader className="space-y-6">
        <CardTitle className="text-2xl text-center">Welcome Back</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-8">
        <Form {...form}>
          <form onSubmit={form.handleSubmit(loginUser)} className="space-y-8" action="POST">
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input {...field} />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="grid gap-4">
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input {...field} type="password" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </div>
            <div className="py-2">
              {status === "loading" ? (
                <Button className="w-full" type="submit" disabled>
                  <Spinner className="mr-2 h-4 w-4 animate-spin" />
                  Sign In
                </Button>
              ) : (
                <Button className="w-full" type="submit">
                  Sign In
                </Button>
              )}
            </div>
          </form>
        </Form>
      </CardContent>
    </Card>
  );
}
export default LoginUser;
