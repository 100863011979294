/* eslint-disable react/prop-types */
import { Button } from "@/components/ui/button";
import { Spinner, CheckCheck } from "@/components/local/icons";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { Form, FormControl, FormField, FormItem, FormLabel, FormMessage } from "@/components/ui/form";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { useForm, validatorResolver, validator, cn } from "@/components/utils";
import { formatDate } from "@/utils";

function CreateContainer({ createRequest, status }) {
  const form = useForm({
    resolver: validatorResolver(
      validator.object({
        firstname: validator.string({
          required_error: "A first name is required."
        }),
        lastname: validator.string({
          required_error: "A last name is required."
        }),
        email: validator.string({
          required_error: "An email is required."
        }),
        gender: validator.string({
          required_error: "A gender is required."
        }),
        dateOfBirth: validator.date({
          required_error: "A date of birth is required."
        }),
        language: validator.string(),
        city: validator.string(),
        state: validator.string(),
        country: validator.string()
      })
    )
  });

  const renderCard = (status) => {
    if (status === "success") {
      return (
        <Card className="p-2 border border-green-500">
          <CardHeader className="space-y-6">
            <CardTitle className="text-2xl text-center text-green-600">Success</CardTitle>
            <CardDescription className="text-center text-black">
              Your request has been successfully submitted.
            </CardDescription>
          </CardHeader>
        </Card>
      );
    } else if (status === "errored") {
      return (
        <Card className="p-2 border border-red-500">
          <CardHeader className="space-y-6">
            <CardTitle className="text-2xl text-center text-red-500">Error</CardTitle>
            <CardDescription className="text-center text-black">
              Oops! Something went wrong. Please try again later.
            </CardDescription>
          </CardHeader>
        </Card>
      );
    } else {
      return (
        <Card className="p-2">
          <CardHeader className="space-y-6">
            <CardTitle className="text-2xl">Create an encyclopedia for your new born</CardTitle>
            <CardDescription>
              Enter your details below and you will recieve a digital version on your email within 24 hours.
            </CardDescription>
          </CardHeader>
          <CardContent className="grid gap-8">
            <Form {...form}>
              <form onSubmit={form.handleSubmit(createRequest)} className="space-y-8" action="POST">
                <div className="flex flex-row gap-4">
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name="firstname"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>First Name</FormLabel>
                          <FormControl>
                            <Input placeholder="John" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="w-full">
                    <FormField
                      control={form.control}
                      name="lastname"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Last Name</FormLabel>
                          <FormControl>
                            <Input placeholder="Doe" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="grid gap-4">
                  <FormField
                    control={form.control}
                    name="email"
                    render={({ field }) => (
                      <FormItem>
                        <FormLabel>Email</FormLabel>
                        <FormControl>
                          <Input placeholder="john@doe.com" {...field} />
                        </FormControl>
                        <FormMessage />
                      </FormItem>
                    )}
                  />
                </div>
                <div className="flex flex-row gap-4 w-full">
                  <div className="grid gap-4 w-1/4">
                    <FormField
                      control={form.control}
                      name="gender"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Gender</FormLabel>
                          <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="male">Male</SelectItem>
                              <SelectItem value="female">Female</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-4 w-2/4">
                    <FormField
                      control={form.control}
                      name="dateOfBirth"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Date of Birth</FormLabel>
                          <Popover>
                            <PopoverTrigger asChild>
                              <FormControl>
                                <Button
                                  variant={"outline"}
                                  className={cn(
                                    "justify-start text-left w-full",
                                    !field.value && "text-muted-foreground"
                                  )}
                                >
                                  {field.value ? formatDate(field.value, "PPP") : <span>Select a date</span>}
                                </Button>
                              </FormControl>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                              <Calendar
                                mode="single"
                                selected={field.value}
                                onSelect={field.onChange}
                                {...field}
                                disabled={(date) => date > new Date() || date < new Date("1900-01-01")}
                              />
                            </PopoverContent>
                          </Popover>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-4 w-1/4">
                    <FormField
                      control={form.control}
                      name="language"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Language</FormLabel>
                          <Select onValueChange={field.onChange} defaultValue={field.value}>
                            <FormControl>
                              <SelectTrigger>
                                <SelectValue placeholder="Select" />
                              </SelectTrigger>
                            </FormControl>
                            <SelectContent>
                              <SelectItem value="english">English</SelectItem>
                            </SelectContent>
                          </Select>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div className="grid gap-4">
                    <FormField
                      control={form.control}
                      name="city"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>City</FormLabel>
                          <FormControl>
                            <Input placeholder="New York City" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-4">
                    <FormField
                      control={form.control}
                      name="state"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>State/Province</FormLabel>
                          <FormControl>
                            <Input placeholder="New York" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                  <div className="grid gap-4">
                    <FormField
                      control={form.control}
                      name="country"
                      render={({ field }) => (
                        <FormItem>
                          <FormLabel>Country</FormLabel>
                          <FormControl>
                            <Input placeholder="USA" {...field} />
                          </FormControl>
                          <FormMessage />
                        </FormItem>
                      )}
                    />
                  </div>
                </div>
                <div className="py-2">
                  {status === "loading" ? (
                    <Button className="w-full" type="submit" disabled>
                      <Spinner className="mr-2 h-4 w-4 animate-spin" />
                      &nbsp;Submit
                    </Button>
                  ) : (
                    <Button className="w-full" type="submit">
                      <CheckCheck className="h-4 w-4" />
                      &nbsp;Submit
                    </Button>
                  )}
                </div>
              </form>
            </Form>
          </CardContent>
        </Card>
      );
    }
  };

  return renderCard(status);
}
export default CreateContainer;
