import { Button } from "@/components/ui/button";
import { FilePlus } from "@/components/local/icons";
import { useNavigate } from "@/components/utils";

function LandingPage() {
  const navigate = useNavigate();
  return (
    <div className="max-w-xl ml-auto mr-auto py-4 px-2">
      <p>
        Birth Encyclopedia may refer to a website or other online source that offers in-depth knowledge on a
        variety of issues connected to pregnancy, childbirth, and associated subjects.
      </p>
      <p>
        Articles, how-to guides, and details about each event and action that took place on the new
        baby&apos;s birth day might be found in such a resource.
      </p>
      <div className="my-8">
        <Button
          className="w-full"
          type="submit"
          onClick={() => {
            navigate("/request");
          }}
        >
          <FilePlus className="h-4 w-4" />
          &nbsp; Create Encyclopedia
        </Button>
      </div>
    </div>
  );
}

export default LandingPage;
