/* eslint-disable react/prop-types */
import React from "react";
import { Sidenav } from "@/components/local/sidenav";
import { Plus } from "@/components/local/icons";
import { ImageViewer, ImageEditor, ImageUploader } from "@/components/local/image";
import { JsonEditor, JsonViewer } from "@/components/local/json";
import { Descriptions } from "@/components/local/descriptions";
import { Textarea } from "@/components/ui/textarea";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogFooter
} from "@/components/ui/dialog";
import { formatDate, parseISO } from "@/utils";
import { useDebouncedFunction } from "@/hooks/useDebounced";
import {
  generateId,
  formatCamelCase,
  filterOutArray,
  generateUploadable,
  isImageDataUri,
  getFromArray,
  replaceById
} from "@/utils";

export function NameContent({ content, update, upload }) {
  const [editingKey, setEditingKey] = React.useState(null);
  const [data, setData] = React.useState(null);
  const [temp, setTemp] = React.useState(null);

  const save = async () => {
    const newName = Object.assign({}, data);
    if (temp?.media) {
      const oldMedia = newName?.media || [];
      const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
      const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
      const mediaToUpload = generateUploadable(mediaToProcess, `names/${data?._id}/`);
      const uploadedMedia = await upload(mediaToUpload);
      if (uploadedMedia.data) {
        newName.media = [...filteredMedia, ...uploadedMedia.data];
      } else {
        newName.media = filteredMedia;
      }
      setData(newName);
    }

    const response = await update(newName);
    if (response.data) setEditingKey(null);
  };

  React.useEffect(() => {
    setData(content?.name);
  }, [content?.name]);

  const renderProperty = ({ key, label, children, type, useEditor }) => {
    const isEditing = editingKey === key;
    return {
      key,
      label,
      type,
      isEditing,
      extras: isEditing ? (
        <div className="flex flex-row justify-between gap-4">
          <div>
            <p className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer" onClick={save}>
              Save
            </p>
          </div>
          <div>
            <p
              className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
              onClick={() => {
                setData(content?.name);
                setEditingKey(null);
              }}
            >
              Cancel
            </p>
          </div>
        </div>
      ) : (
        <p
          className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
          onClick={() => setEditingKey(key)}
        >
          Edit
        </p>
      ),
      children: isEditing ? (
        typeof useEditor === "string" ? (
          <div>
            {useEditor === "array" && (
              <div>
                <Textarea
                  onChange={(e) => {
                    setData((prevData) => {
                      return { ...prevData, [key]: e.target.value.replace(/\s/g, "").split(",") };
                    });
                  }}
                  value={children}
                />
                <p className="text-gray-400 text-xs font-light mt-1 ml-1">Use commas to separate words.</p>
              </div>
            )}
            {useEditor === "json" && (
              <JsonEditor
                data={data[key]}
                update={(payload) => {
                  setData((prevData) => {
                    return { ...prevData, [key]: payload };
                  });
                }}
              />
            )}
            {useEditor === "image" && (
              <div className="flex gap-4">
                <div className="flex justify-center items-center">
                  <Dialog>
                    <DialogTrigger asChild>
                      <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                    </DialogTrigger>
                    <DialogContent className="max-w-[625px]">
                      <DialogHeader>
                        <DialogTitle className="mb-2">Upload Media</DialogTitle>
                        <DialogDescription>
                          File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File size : 5mb.
                        </DialogDescription>
                      </DialogHeader>
                      <div className="flex flex-col">
                        <ImageUploader
                          onFinish={(data) => {
                            setTemp((prevData) => {
                              return { ...prevData, [key]: data };
                            });
                          }}
                        />
                      </div>
                      <DialogFooter>
                        <DialogClose asChild>
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              {temp && temp[key] ? (
                                <p
                                  className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                  onClick={async () => {
                                    if (temp[key]) {
                                      setData((prevData) => {
                                        return { ...prevData, [key]: [...prevData[key], ...temp[key]] };
                                      });
                                    }
                                  }}
                                >
                                  Add
                                </p>
                              ) : (
                                <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                              )}
                            </div>
                            <div>
                              <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">Cancel</p>
                            </div>
                          </div>
                        </DialogClose>
                      </DialogFooter>
                    </DialogContent>
                  </Dialog>
                </div>
                <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                  {Array.isArray(children) && children.length > 0 ? (
                    children.map((src, index) => {
                      return (
                        <div key={index} className="flex-shrink-0">
                          <ImageEditor
                            onEdit={(value) => {
                              const updatedValue = filterOutArray(value, data[key]);
                              setData((prevData) => {
                                return { ...prevData, [key]: updatedValue };
                              });
                            }}
                            src={src}
                          />
                        </div>
                      );
                    })
                  ) : (
                    <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                  )}
                </div>
              </div>
            )}
          </div>
        ) : (
          <div>
            <Textarea
              onChange={(e) => {
                setData((prevData) => {
                  return { ...prevData, [key]: e.target.value };
                });
              }}
              value={children}
            />
          </div>
        )
      ) : (
        children
      )
    };
  };

  const renderProperties = () => {
    const properties = [
      { key: "gender", label: "Gender", children: data?.gender, useEditor: false },
      { key: "origin", label: "Origin", children: data?.origin, useEditor: false },
      { key: "color", label: "Color", children: data?.color, useEditor: false },
      {
        key: "colorDescription",
        label: "Color Description",
        children: data?.colorDescription,
        useEditor: false
      },
      { key: "chakraNumber", label: "Chakra Number", children: data?.chakraNumber, useEditor: false },
      {
        key: "chakraNumberDescription",
        label: "Chakra Number Description",
        children: data?.chakraNumberDescription,
        useEditor: false
      },
      {
        key: "numerologicalValue",
        label: "Numerological Value",
        children: data?.numerologicalValue,
        useEditor: false
      },
      {
        key: "numerologicalValueDescription",
        label: "Numerological Value Description",
        children: data?.numerologicalValueDescription,
        useEditor: false
      },
      {
        key: "popularOpinion",
        label: "Popular Opinion",
        children: data?.popularOpinion.join(" , "),
        useEditor: "array"
      },
      { key: "meaning", label: "Meaning", children: data?.meaning, useEditor: false },
      { key: "usage", label: "Usage", children: data?.usage.join(" , "), useEditor: false },
      {
        key: "relatedNames",
        label: "Related Names",
        children: data?.relatedNames.join(" , "),
        useEditor: "array"
      },
      { key: "pronounced", label: "Pronounced", children: data?.pronounced, useEditor: false },
      {
        key: "namedays",
        label: "Namedays",
        children: <JsonViewer data={data?.namedays} />,
        useEditor: "json"
      },
      { key: "description", label: "Description", children: data?.description, useEditor: false },
      {
        key: "rating",
        label: "Rating",
        children: <JsonViewer data={data?.rating} />,
        useEditor: "json"
      },
      { key: "nameRoot", label: "Name Root", children: data?.nameRoot, useEditor: false },
      {
        key: "popularUsage",
        label: "Popular Usage",
        children: data?.popularUsage.join(" , "),
        useEditor: "array"
      },
      { key: "namesakes", label: "Namesakes", children: data?.namesakes.join(" , "), useEditor: false },
      {
        key: "equivalents",
        label: "Equivalents",
        children: <JsonViewer data={data?.equivalents} />,
        useEditor: "json"
      },
      {
        key: "useInLanguages",
        label: "Use In Other Languages",
        children: <JsonViewer data={data?.useInLanguages} />,
        useEditor: "json"
      },
      {
        key: "diminutives",
        label: "Diminutives",
        children: <JsonViewer data={data?.diminutives} />,
        useEditor: "json"
      },
      {
        key: "extras",
        label: "Extras",
        children: <JsonViewer data={data?.extras} />,
        useEditor: "json"
      },
      {
        key: "otherRelations",
        label: "Other Relations",
        children: <JsonViewer data={data?.otherRelations} />,
        useEditor: "json"
      },
      {
        key: "media",
        label: "Media",
        type: "image",
        children: data?.media,
        useEditor: "image"
      }
    ];

    return properties.map(({ key, label, useEditor, type, children }) =>
      renderProperty({ key, label, children, type, useEditor })
    );
  };
  return (
    <div>
      {content && content.name ? (
        <div className="max-h-392 overflow-y-auto">
          <Descriptions
            title={
              <div className="flex items-center justify-between text-xs font-light text-gray-800">
                <div className="flex items-center justify-between">
                  <div>Fulfillment ID : {content?._id} &nbsp; | &nbsp; </div>
                  <div>{content?.createdAt && formatDate(parseISO(content?.createdAt), "yyyy-MM-dd")}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Name ID : {content?.name?._id} &nbsp; | &nbsp; </div>
                  <div>
                    {content?.name?.createdAt && formatDate(parseISO(content?.name?.createdAt), "yyyy-MM-dd")}
                  </div>
                </div>
              </div>
            }
            items={data ? renderProperties() : []}
          />
        </div>
      ) : (
        <div className="text-center m-4">
          <p>No Results</p>
        </div>
      )}
    </div>
  );
}

export function DayContent({ content, update, upload }) {
  update = useDebouncedFunction(update);
  const [navigation, setNavigation] = React.useState("general-birthdays");
  const [editingKey, setEditingKey] = React.useState(null);
  const [general, setGeneral] = React.useState(null);
  const [film, setFilm] = React.useState(null);
  const [music, setMusic] = React.useState(null);
  const [sport, setSport] = React.useState(null);

  const [temp, setTemp] = React.useState(null);

  React.useEffect(() => {
    setGeneral(content?.day?.general);
  }, [content?.day?.general]);
  React.useEffect(() => {
    setFilm(content?.day?.film);
  }, [content?.day?.film]);
  React.useEffect(() => {
    setMusic(content?.day?.music);
  }, [content?.day?.music]);
  React.useEffect(() => {
    setSport(content?.day?.sport);
  }, [content?.day?.sport]);

  const resetState = () => {
    setGeneral(content?.day?.general);
    setFilm(content?.day?.film);
    setMusic(content?.day?.music);
    setSport(content?.day?.sport);
  };

  const renderNavigator = (key) => {
    switch (key) {
      case "general-birthdays":
        return renderGeneral.birthdays();
      case "general-events":
        return renderGeneral.events();
      case "general-weddings":
        return renderGeneral.weddings();
      case "general-celebratory":
        return renderGeneral.celebratory();
      case "film-birthdays":
        return renderFilm.birthdays();
      case "film-events":
        return renderFilm.events();
      case "film-weddings":
        return renderFilm.weddings();
      case "music-birthdays":
        return renderMusic.birthdays();
      case "music-events":
        return renderMusic.events();
      case "music-weddings":
        return renderMusic.weddings();
      case "sport-birthdays":
        return renderSport.birthdays();
      case "sport-events":
        return renderSport.events();
      case "sport-weddings":
        return renderSport.weddings();

      default:
        return (
          <div className="text-center m-4">
            <p>No Results</p>
          </div>
        );
    }
  };

  const renderGeneral = {
    birthdays: function Birthdays() {
      const birthdays = general?.birthdays;
      const setBirthdays = (newBirthdays) => {
        setGeneral((prevData) => {
          return { ...prevData, birthdays: newBirthdays };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newBirthdays = [...birthdays];
          const oldMedia = getFromArray(key, birthdays)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newBirthdays = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              birthdays
            );
          } else {
            newBirthdays = replaceById(key, { ...item, media: filteredMedia }, birthdays);
          }
          setBirthdays(newBirthdays);
          payload.general.birthdays = newBirthdays;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {birthdays && (Array.isArray(birthdays) || birthdays.length > 0) ? (
              birthdays.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => {
                              setEditingKey(key);
                            }}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, year: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.year}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.description}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, birthdays)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newBirthdays = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  birthdays
                                                );
                                                setBirthdays(newBirthdays);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newBirthdays = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          birthdays
                                        );
                                        setBirthdays(newBirthdays);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    events: function Events() {
      const events = general?.events;
      const setEvents = (newEvents) => {
        setGeneral((prevData) => {
          return { ...prevData, events: newEvents };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newEvents = [...events];
          const oldMedia = getFromArray(key, events)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newEvents = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              events
            );
          } else {
            newEvents = replaceById(key, { ...item, media: filteredMedia }, events);
          }
          setEvents(newEvents);
          payload.general.events = newEvents;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {events && (Array.isArray(events) || events.length > 0) ? (
              events.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newEvents = replaceById(key, { ...item, year: e.target.value }, events);
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newEvents = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              events
                            );
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, events)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newEvents = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  events
                                                );
                                                setEvents(newEvents);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newEvents = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          events
                                        );
                                        setEvents(newEvents);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    weddings: function Weddings() {
      const weddings = general?.weddingsDivorces;
      const setWeddings = (newWeddings) => {
        setGeneral((prevData) => {
          return { ...prevData, weddingsDivorces: newWeddings };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newWeddings = [...weddings];
          const oldMedia = getFromArray(key, weddings)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newWeddings = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              weddings
            );
          } else {
            newWeddings = replaceById(key, { ...item, media: filteredMedia }, weddings);
          }
          setWeddings(newWeddings);
          payload.general.weddingsDivorces = newWeddings;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {weddings && (Array.isArray(weddings) || weddings.length > 0) ? (
              weddings.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newWeddings = replaceById(key, { ...item, year: e.target.value }, weddings);
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newWeddings = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              weddings
                            );
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, weddings)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newWeddings = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  weddings
                                                );
                                                setWeddings(newWeddings);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newWeddings = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          weddings
                                        );
                                        setWeddings(newWeddings);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    celebratory: function Celebratory() {
      const celebratoryDays = general?.celebratoryDays;
      const setCelebratoryDays = (newCelebratoryDays) => {
        setGeneral((prevData) => {
          return { ...prevData, celebratoryDays: newCelebratoryDays };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newCelebratoryDays = [...celebratoryDays];
          const oldMedia = getFromArray(key, celebratoryDays)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newCelebratoryDays = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              celebratoryDays
            );
          } else {
            newCelebratoryDays = replaceById(key, { ...item, media: filteredMedia }, celebratoryDays);
          }
          setCelebratoryDays(newCelebratoryDays);
          payload.general.celebratoryDays = newCelebratoryDays;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {celebratoryDays && (Array.isArray(celebratoryDays) || celebratoryDays.length > 0) ? (
              celebratoryDays.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newCelebratoryDays = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              celebratoryDays
                            );
                            setCelebratoryDays(newCelebratoryDays);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newCelebratoryDays = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              celebratoryDays
                            );
                            setCelebratoryDays(newCelebratoryDays);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia =
                                                  getFromArray(key, celebratoryDays)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newCelebratoryDays = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  celebratoryDays
                                                );
                                                setCelebratoryDays(newCelebratoryDays);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newCelebratoryDays = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          celebratoryDays
                                        );
                                        setCelebratoryDays(newCelebratoryDays);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };
  const renderFilm = {
    birthdays: function Birthdays() {
      const birthdays = film?.birthdays;
      const setBirthdays = (newBirthdays) => {
        setFilm((prevData) => {
          return { ...prevData, birthdays: newBirthdays };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newBirthdays = [...birthdays];
          const oldMedia = getFromArray(key, birthdays)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newBirthdays = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              birthdays
            );
          } else {
            newBirthdays = replaceById(key, { ...item, media: filteredMedia }, birthdays);
          }
          setBirthdays(newBirthdays);
          payload.film.birthdays = newBirthdays;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {birthdays && (Array.isArray(birthdays) || birthdays.length > 0) ? (
              birthdays.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => {
                              setEditingKey(key);
                            }}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, year: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.year}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.description}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, birthdays)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newBirthdays = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  birthdays
                                                );
                                                setBirthdays(newBirthdays);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newBirthdays = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          birthdays
                                        );
                                        setBirthdays(newBirthdays);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    events: function Events() {
      const events = film?.events;
      const setEvents = (newEvents) => {
        setFilm((prevData) => {
          return { ...prevData, events: newEvents };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newEvents = [...events];
          const oldMedia = getFromArray(key, events)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newEvents = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              events
            );
          } else {
            newEvents = replaceById(key, { ...item, media: filteredMedia }, events);
          }
          setEvents(newEvents);
          payload.film.events = newEvents;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {events && (Array.isArray(events) || events.length > 0) ? (
              events.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newEvents = replaceById(key, { ...item, year: e.target.value }, events);
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newEvents = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              events
                            );
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, events)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newEvents = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  events
                                                );
                                                setEvents(newEvents);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newEvents = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          events
                                        );
                                        setEvents(newEvents);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    weddings: function Weddings() {
      const weddings = film?.weddingsDivorces;
      const setWeddings = (newWeddings) => {
        setFilm((prevData) => {
          return { ...prevData, weddingsDivorces: newWeddings };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newWeddings = [...weddings];
          const oldMedia = getFromArray(key, weddings)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newWeddings = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              weddings
            );
          } else {
            newWeddings = replaceById(key, { ...item, media: filteredMedia }, weddings);
          }
          setWeddings(newWeddings);
          payload.film.weddingsDivorces = newWeddings;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {weddings && (Array.isArray(weddings) || weddings.length > 0) ? (
              weddings.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newWeddings = replaceById(key, { ...item, year: e.target.value }, weddings);
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newWeddings = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              weddings
                            );
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, weddings)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newWeddings = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  weddings
                                                );
                                                setWeddings(newWeddings);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newWeddings = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          weddings
                                        );
                                        setWeddings(newWeddings);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };
  const renderMusic = {
    birthdays: function Birthdays() {
      const birthdays = music?.birthdays;
      const setBirthdays = (newBirthdays) => {
        setMusic((prevData) => {
          return { ...prevData, birthdays: newBirthdays };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newBirthdays = [...birthdays];
          const oldMedia = getFromArray(key, birthdays)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newBirthdays = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              birthdays
            );
          } else {
            newBirthdays = replaceById(key, { ...item, media: filteredMedia }, birthdays);
          }
          setBirthdays(newBirthdays);
          payload.music.birthdays = newBirthdays;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {birthdays && (Array.isArray(birthdays) || birthdays.length > 0) ? (
              birthdays.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => {
                              setEditingKey(key);
                            }}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, year: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.year}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.description}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, birthdays)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newBirthdays = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  birthdays
                                                );
                                                setBirthdays(newBirthdays);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newBirthdays = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          birthdays
                                        );
                                        setBirthdays(newBirthdays);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    events: function Events() {
      const events = music?.events;
      const setEvents = (newEvents) => {
        setMusic((prevData) => {
          return { ...prevData, events: newEvents };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newEvents = [...events];
          const oldMedia = getFromArray(key, events)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newEvents = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              events
            );
          } else {
            newEvents = replaceById(key, { ...item, media: filteredMedia }, events);
          }
          setEvents(newEvents);
          payload.music.events = newEvents;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {events && (Array.isArray(events) || events.length > 0) ? (
              events.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newEvents = replaceById(key, { ...item, year: e.target.value }, events);
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newEvents = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              events
                            );
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, events)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newEvents = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  events
                                                );
                                                setEvents(newEvents);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newEvents = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          events
                                        );
                                        setEvents(newEvents);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    weddings: function Weddings() {
      const weddings = music?.weddingsDivorces;
      const setWeddings = (newWeddings) => {
        setMusic((prevData) => {
          return { ...prevData, weddingsDivorces: newWeddings };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newWeddings = [...weddings];
          const oldMedia = getFromArray(key, weddings)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newWeddings = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              weddings
            );
          } else {
            newWeddings = replaceById(key, { ...item, media: filteredMedia }, weddings);
          }
          setWeddings(newWeddings);
          payload.music.weddingsDivorces = newWeddings;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {weddings && (Array.isArray(weddings) || weddings.length > 0) ? (
              weddings.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newWeddings = replaceById(key, { ...item, year: e.target.value }, weddings);
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newWeddings = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              weddings
                            );
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, weddings)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newWeddings = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  weddings
                                                );
                                                setWeddings(newWeddings);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newWeddings = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          weddings
                                        );
                                        setWeddings(newWeddings);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };
  const renderSport = {
    birthdays: function Birthdays() {
      const birthdays = sport?.birthdays;
      const setBirthdays = (newBirthdays) => {
        setSport((prevData) => {
          return { ...prevData, birthdays: newBirthdays };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newBirthdays = [...birthdays];
          const oldMedia = getFromArray(key, birthdays)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newBirthdays = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              birthdays
            );
          } else {
            newBirthdays = replaceById(key, { ...item, media: filteredMedia }, birthdays);
          }
          setBirthdays(newBirthdays);
          payload.sport.birthdays = newBirthdays;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {birthdays && (Array.isArray(birthdays) || birthdays.length > 0) ? (
              birthdays.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => {
                              setEditingKey(key);
                            }}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, year: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.year}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newBirthdays = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              birthdays
                            );
                            setBirthdays(newBirthdays);
                          }}
                          className="mt-1"
                          value={item.description}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, birthdays)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newBirthdays = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  birthdays
                                                );
                                                setBirthdays(newBirthdays);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newBirthdays = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          birthdays
                                        );
                                        setBirthdays(newBirthdays);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    events: function Events() {
      const events = sport?.events;
      const setEvents = (newEvents) => {
        setSport((prevData) => {
          return { ...prevData, events: newEvents };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newEvents = [...events];
          const oldMedia = getFromArray(key, events)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newEvents = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              events
            );
          } else {
            newEvents = replaceById(key, { ...item, media: filteredMedia }, events);
          }
          setEvents(newEvents);
          payload.sport.events = newEvents;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {events && (Array.isArray(events) || events.length > 0) ? (
              events.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newEvents = replaceById(key, { ...item, year: e.target.value }, events);
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newEvents = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              events
                            );
                            setEvents(newEvents);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, events)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newEvents = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  events
                                                );
                                                setEvents(newEvents);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newEvents = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          events
                                        );
                                        setEvents(newEvents);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    weddings: function Weddings() {
      const weddings = sport?.weddingsDivorces;
      const setWeddings = (newWeddings) => {
        setSport((prevData) => {
          return { ...prevData, weddingsDivorces: newWeddings };
        });
      };
      const save = async (key, item) => {
        const id = content.day._id;
        const payload = { _id: id, general, film, music, sport };
        if (temp?.media) {
          let newWeddings = [...weddings];
          const oldMedia = getFromArray(key, weddings)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `days/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newWeddings = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              weddings
            );
          } else {
            newWeddings = replaceById(key, { ...item, media: filteredMedia }, weddings);
          }
          setWeddings(newWeddings);
          payload.sport.weddingsDivorces = newWeddings;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {weddings && (Array.isArray(weddings) || weddings.length > 0) ? (
              weddings.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Year</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.year}
                          onChange={(e) => {
                            const newWeddings = replaceById(key, { ...item, year: e.target.value }, weddings);
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.year}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.description}
                          onChange={(e) => {
                            const newWeddings = replaceById(
                              key,
                              { ...item, description: e.target.value },
                              weddings
                            );
                            setWeddings(newWeddings);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.description}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, weddings)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newWeddings = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  weddings
                                                );
                                                setWeddings(newWeddings);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newWeddings = replaceById(
                                          key,
                                          {
                                            ...item,
                                            media: updatedValue
                                          },
                                          weddings
                                        );
                                        setWeddings(newWeddings);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };
  return (
    <div>
      {" "}
      {content && content.day ? (
        <div className="grid grid-cols-12">
          <div className="col-span-2 ">
            <Sidenav
              items={[
                {
                  label: (
                    <span className={`${navigation.includes("general") ? "text-black" : ""}`}>General</span>
                  ),
                  children: [
                    {
                      key: "general-birthdays",
                      label: (
                        <span
                          className={`${
                            navigation === "general-birthdays"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("general-birthdays");
                          }}
                        >
                          Birthdays
                        </span>
                      )
                    },
                    {
                      key: "general-events",
                      label: (
                        <span
                          className={`${
                            navigation === "general-events"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("general-events");
                          }}
                        >
                          Events
                        </span>
                      )
                    },
                    {
                      key: "general-weddings",
                      label: (
                        <span
                          className={`${
                            navigation === "general-weddings"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("general-weddings");
                          }}
                        >
                          Weddings & Divorces
                        </span>
                      )
                    },
                    {
                      key: "general-celebratory",
                      label: (
                        <span
                          className={`${
                            navigation === "general-celebratory"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("general-celebratory");
                          }}
                        >
                          Celebratory Days
                        </span>
                      )
                    }
                  ]
                },
                {
                  label: <span className={`${navigation.includes("film") ? "text-black" : ""}`}>Film</span>,
                  children: [
                    {
                      key: "film-birthdays",
                      label: (
                        <span
                          className={`${
                            navigation === "film-birthdays"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("film-birthdays");
                          }}
                        >
                          Birthdays
                        </span>
                      )
                    },
                    {
                      key: "film-events",
                      label: (
                        <span
                          className={`${
                            navigation === "film-events"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("film-events");
                          }}
                        >
                          Events
                        </span>
                      )
                    },
                    {
                      key: "film-weddings",
                      label: (
                        <span
                          className={`${
                            navigation === "film-weddings"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("film-weddings");
                          }}
                        >
                          Weddings & Divorces
                        </span>
                      )
                    }
                  ]
                },
                {
                  label: <span className={`${navigation.includes("music") ? "text-black" : ""}`}>Music</span>,
                  children: [
                    {
                      key: "music-birthdays",
                      label: (
                        <span
                          className={`${
                            navigation === "music-birthdays"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("music-birthdays");
                          }}
                        >
                          Birthdays
                        </span>
                      )
                    },
                    {
                      key: "music-events",
                      label: (
                        <span
                          className={`${
                            navigation === "music-events"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("music-events");
                          }}
                        >
                          Events
                        </span>
                      )
                    },
                    {
                      key: "music-weddings",
                      label: (
                        <span
                          className={`${
                            navigation === "music-weddings"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("music-weddings");
                          }}
                        >
                          Weddings & Divorces
                        </span>
                      )
                    }
                  ]
                },
                {
                  label: <span className={`${navigation.includes("sport") ? "text-black" : ""}`}>Sport</span>,
                  children: [
                    {
                      key: "sport-birthdays",
                      label: (
                        <span
                          className={`${
                            navigation === "sport-birthdays"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("sport-birthdays");
                          }}
                        >
                          Birthdays
                        </span>
                      )
                    },
                    {
                      key: "sport-events",
                      label: (
                        <span
                          className={`${
                            navigation === "sport-events"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("sport-events");
                          }}
                        >
                          Events
                        </span>
                      )
                    },
                    {
                      key: "sport-weddings",
                      label: (
                        <span
                          className={`${
                            navigation === "sport-weddings"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("sport-weddings");
                          }}
                        >
                          Weddings & Divorces
                        </span>
                      )
                    }
                  ]
                }
              ]}
            />
          </div>
          <div className="col-span-10 -ml-8 max-h-392 overflow-y-auto">
            <div className="w-full mx-auto border bg-card text-card-foreground mb-2 px-6 py-3">
              <div className="flex items-center justify-between text-xs font-light text-gray-800">
                <div className="flex items-center justify-between">
                  <div>Fulfillment ID : {content?._id} &nbsp; | &nbsp; </div>
                  <div>{content?.createdAt && formatDate(parseISO(content?.createdAt), "yyyy-MM-dd")}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Day ID : {content?.day?._id} &nbsp; | &nbsp; </div>
                  <div>
                    {content?.day?.createdAt && formatDate(parseISO(content?.day?.createdAt), "yyyy-MM-dd")}
                  </div>
                </div>
              </div>
            </div>
            <div>{renderNavigator(navigation)}</div>
          </div>
        </div>
      ) : (
        <div className="text-center m-4">
          <p>No Results</p>
        </div>
      )}
    </div>
  );
}

export function DateContent({ content, update, upload }) {
  update = useDebouncedFunction(update);
  const [navigation, setNavigation] = React.useState("statistics-economy");
  const [editingKey, setEditingKey] = React.useState(null);
  const [statistics, setStatistics] = React.useState(null);
  const [pages, setPages] = React.useState(null);
  const [news, setNews] = React.useState(null);
  const [trends, setTrends] = React.useState(null);

  const [temp, setTemp] = React.useState(null);

  React.useEffect(() => {
    setStatistics({
      economy: content?.date?.statistics?.economy,
      population: content?.date?.statistics?.population,
      challenges: content?.date?.statistics?.challenges
    });
  }, [content?.date?.statistics]);
  React.useEffect(() => {
    setPages({ magazine: content?.date?.pages?.magazine, newspaper: content?.date?.pages?.newspaper });
  }, [content?.date?.pages]);
  React.useEffect(() => {
    setNews({
      top: content?.date?.news?.top,
      world: content?.date?.news?.world,
      africa: content?.date?.news?.africa,
      americas: content?.date?.news?.americas,
      asiaPacific: content?.date?.news?.asiaPacific,
      china: content?.date?.news?.china,
      europe: content?.date?.news?.europe,
      india: content?.date?.news?.india,
      middleEast: content?.date?.news?.middleEast,
      unitedKingdom: content?.date?.news?.unitedKingdom,
      unitedStates: content?.date?.news?.unitedStates
    });
  }, [content?.date?.news]);
  React.useEffect(() => {
    setTrends({
      general: content?.date?.trends?.general,
      social: content?.date?.trends?.social,
      environmental: content?.date?.trends?.environmental,
      music: content?.date?.trends?.music,
      movies: content?.date?.trends?.movies
    });
  }, [content?.date?.trends]);

  const resetState = () => {
    setStatistics({
      economy: content?.date?.statistics?.economy,
      population: content?.date?.statistics?.population,
      challenges: content?.date?.statistics?.challenges
    });
    setPages({ magazine: content?.date?.pages?.magazine, newspaper: content?.date?.pages?.newspaper });
    setNews({
      top: content?.date?.news?.top,
      world: content?.date?.news?.world,
      africa: content?.date?.news?.africa,
      americas: content?.date?.news?.americas,
      asiaPacific: content?.date?.news?.asiaPacific,
      china: content?.date?.news?.china,
      europe: content?.date?.news?.europe,
      india: content?.date?.news?.india,
      middleEast: content?.date?.news?.middleEast,
      unitedKingdom: content?.date?.news?.unitedKingdom,
      unitedStates: content?.date?.news?.unitedStates
    });
    setTrends({
      general: content?.date?.trends?.general,
      social: content?.date?.trends?.social,
      environmental: content?.date?.trends?.environmental,
      music: content?.date?.trends?.music,
      movies: content?.date?.trends?.movies
    });
  };

  const renderNavigator = (key) => {
    switch (key) {
      case "statistics-economy":
        return renderStatistics.economy();
      case "statistics-population":
        return renderStatistics.population();
      case "statistics-challenges":
        return renderStatistics.challenges();
      case "pages-magazine":
        return renderPages.magazine();
      case "pages-paper":
        return renderPages.newspaper();
      case "news-top":
        return renderNews.top();
      case "news-world":
        return renderNews.world();
      case "news-africa":
        return renderNews.africa();
      case "news-americas":
        return renderNews.americas();
      case "news-asiaPacific":
        return renderNews.asiaPacific();
      case "news-china":
        return renderNews.china();
      case "news-europe":
        return renderNews.europe();
      case "news-india":
        return renderNews.india();
      case "news-middleEast":
        return renderNews.middleEast();
      case "news-unitedKingdom":
        return renderNews.unitedKingdom();
      case "news-unitedStates":
        return renderNews.unitedStates();
      case "trends-general":
        return renderTrends.general();
      case "trends-environmental":
        return renderTrends.environmental();
      case "trends-social":
        return renderTrends.social();
      case "trends-music":
        return renderTrends.music();
      case "trends-movies":
        return renderTrends.movies();

      default:
        return (
          <div className="text-center m-4">
            <p>No Results</p>
          </div>
        );
    }
  };
  const renderStatistics = {
    economy: function Economy() {
      const economy = statistics?.economy;
      const setEconomy = (newEconomy) => {
        setStatistics((prevData) => {
          return { ...prevData, economy: newEconomy };
        });
      };
      const save = async () => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      const renderProperty = ({ key, label, children }) => {
        const isEditing = editingKey === key;

        return {
          key,
          label,
          extras: isEditing ? (
            <div className="flex flex-row justify-between gap-4">
              <div>
                <p className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer" onClick={save}>
                  Save
                </p>
              </div>
              <div>
                <p
                  className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                  onClick={() => {
                    setEditingKey(null);
                    resetState();
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          ) : (
            <p
              className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
              onClick={() => setEditingKey(key)}
            >
              Edit
            </p>
          ),
          children: isEditing ? (
            <Textarea
              value={children}
              onChange={(e) => {
                setEconomy({ ...economy, [key]: e.target.value });
              }}
            />
          ) : (
            children
          )
        };
      };

      const renderProperties = () => {
        const properties = [
          {
            key: "grossWorldProduct",
            label: "Gross World Product (US$)",
            children: economy?.grossWorldProduct
          },
          { key: "CO2Emitted", label: "CO2 Emitted (Tons)", children: economy?.CO2Emitted },
          { key: "adSpend", label: "Ad Spend (US$)", children: economy?.adSpend },
          {
            key: "agriculturalSector",
            label: "Agricultural Sector (US$)",
            children: economy?.agriculturalSector
          },
          {
            key: "chemicalsProduced",
            label: "Chemicals Produced (Tons)",
            children: economy?.chemicalsProduced
          },
          {
            key: "consumers",
            label: "Consumers",
            children: economy?.consumers
          },
          {
            key: "energyStrikingEarth",
            label: "Energy Striking Earth (Terajoules)",
            children: economy?.energyStrikingEarth
          },
          {
            key: "energyUsed",
            label: "Energy Used (Terajoules)",
            children: economy?.energyUsed
          },
          {
            key: "globalConsumerSpending",
            label: "Global Consumer Spending (US$)",
            children: economy?.globalConsumerSpending
          },
          {
            key: "industrialSector",
            label: "Industrial Sector (US$)",
            children: economy?.industrialSector
          },
          {
            key: "manufacturingSector",
            label: "Manufacturing Sector (US$)",
            children: economy?.manufacturingSector
          },
          {
            key: "serviceSector",
            label: "Service Sector (US$)",
            children: economy?.serviceSector
          },

          {
            key: "moneyInvested",
            label: "Money Invested (US$)",
            children: economy?.moneyInvested
          },
          {
            key: "renewableElectricity",
            label: "Renewable Electricity (%)",
            children: economy?.renewableElectricity
          },
          {
            key: "resourcesExtracted",
            label: "Resources Extracted (Terajoules)",
            children: economy?.resourcesExtracted
          },
          {
            key: "steelProduced",
            label: "Steel Produced (Tons)",
            children: economy?.steelProduced
          },
          {
            key: "worldAverageTemperature",
            label: "World Average Temperature (°C)",
            children: economy?.worldAverageTemperature
          }
        ];

        return properties.map(({ key, label, children }) => renderProperty({ key, label, children }));
      };
      return (
        <div className="">
          <Descriptions columns={4} items={economy ? renderProperties() : []} />
        </div>
      );
    },
    population: function Population() {
      const population = statistics?.population;
      const setPopulation = (newPopulation) => {
        setStatistics((prevData) => {
          return { ...prevData, population: newPopulation };
        });
      };
      const save = async () => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      const renderProperty = ({ key, label, children }) => {
        const isEditing = editingKey === key;

        return {
          key,
          label,
          extras: isEditing ? (
            <div className="flex flex-row justify-between gap-4">
              <div>
                <p className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer" onClick={save}>
                  Save
                </p>
              </div>
              <div>
                <p
                  className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                  onClick={() => {
                    setEditingKey(null);
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          ) : (
            <p
              className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
              onClick={() => setEditingKey(key)}
            >
              Edit
            </p>
          ),
          children: isEditing ? (
            <Textarea
              value={children}
              onChange={(e) => {
                setPopulation({ ...population, [key]: e.target.value });
              }}
            />
          ) : (
            children
          )
        };
      };

      const renderProperties = () => {
        const properties = [
          {
            key: "current",
            label: "Current",
            children: population?.current
          },
          {
            key: "birth",
            label: "Births",
            children: population?.birth
          },
          { key: "urban", label: "Urban", children: population?.urban },
          {
            key: "rural",
            label: "Rural",
            children: population?.rural
          },
          {
            key: "africa",
            label: "Africa",
            children: population?.africa
          },
          {
            key: "antarctica",
            label: "Antarctica",
            children: population?.antarctica
          },
          {
            key: "asia",
            label: "Asia",
            children: population?.asia
          },
          {
            key: "europe",
            label: "Europe",
            children: population?.europe
          },
          {
            key: "northAmerica",
            label: "North America",
            children: population?.northAmerica
          },
          {
            key: "southAmerica",
            label: "South America",
            children: population?.southAmerica
          },
          {
            key: "oceania",
            label: "Oceania",
            children: population?.oceania
          },
          {
            key: "planetsRequired",
            label: "Planets Required",
            children: population?.planetsRequired
          }
        ];

        return properties.map(({ key, label, children }) => renderProperty({ key, label, children }));
      };
      return (
        <div className="">
          <div className="flex flex-col gap-2">
            <div>
              <Descriptions columns={4} items={population ? renderProperties() : []} />
            </div>
            <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
              <div className={`grid grid-cols-4`}>
                {population?.global &&
                (Array.isArray(population?.global) || population?.global?.length > 0) ? (
                  population?.global?.map((item, index) => {
                    const key = index;
                    const isEditing = editingKey === key;
                    return (
                      <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Rank</p>
                            {isEditing ? (
                              <div className="flex flex-row justify-between gap-4">
                                <div>
                                  <p
                                    className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                    onClick={async () => {
                                      setEditingKey(null);
                                    }}
                                  >
                                    Save
                                  </p>
                                </div>
                                <div>
                                  <p
                                    className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                    onClick={() => {
                                      resetState();
                                      setEditingKey(null);
                                    }}
                                  >
                                    Cancel
                                  </p>
                                </div>
                              </div>
                            ) : (
                              <p
                                className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                                onClick={() => setEditingKey(key)}
                              >
                                Edit
                              </p>
                            )}
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.rank}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, rank: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.rank}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Country</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.country}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, country: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.country}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Population</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.population}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, population: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.population}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Density</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.density}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, density: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.density}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Fertility Rate (%)</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.fertilityRate}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, fertilityRate: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.fertilityRate}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Land Area</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.landArea}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, landArea: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.landArea}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Median Age (Years)</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.medianAge}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, medianAge: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.medianAge}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Migrants</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.migrants}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, migrants: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.migrants}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Net Change</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.netChange}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, netChange: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.netChange}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Urban Population</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.urbanPopulation}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, urbanPopulation: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.urbanPopulation}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Yearly Change</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.yearlyChange}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, yearlyChange: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.yearlyChange}</div>
                          )}
                        </div>
                        <div>
                          <div className="flex flex-row justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">World Share</p>
                          </div>
                          {isEditing ? (
                            <Textarea
                              className="mt-1"
                              value={item.worldShare}
                              onChange={(e) => {
                                const newGlobal = [...population.global];
                                newGlobal[index] = { ...item, worldShare: e.target.value };
                                const newPopulation = { ...population, global: newGlobal };
                                setPopulation(newPopulation);
                              }}
                            />
                          ) : (
                            <div className="text-sm max-h-40 overflow-auto">{item.worldShare}</div>
                          )}
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <div className="text-center m-4">
                    <p>No Results</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      );
    },
    challenges: function Challenges() {
      const challenges = statistics?.challenges;
      const setChallenges = (newChallenges) => {
        setStatistics((prevData) => {
          return { ...prevData, challenges: newChallenges };
        });
      };
      const save = async () => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      const renderProperty = (key, label, children) => {
        const isEditing = editingKey === key;

        return {
          key,
          label,
          extras: isEditing ? (
            <div className="flex flex-row justify-between gap-4">
              <div>
                <p className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer" onClick={save}>
                  Save
                </p>
              </div>
              <div>
                <p
                  className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                  onClick={() => {
                    setEditingKey(null);
                  }}
                >
                  Cancel
                </p>
              </div>
            </div>
          ) : (
            <p
              className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
              onClick={() => setEditingKey(key)}
            >
              Edit
            </p>
          ),
          children: isEditing ? (
            <Textarea
              value={children}
              onChange={(e) => {
                setChallenges({ ...challenges, [key]: e.target.value });
              }}
            />
          ) : (
            children
          )
        };
      };

      const renderProperties = () => {
        const properties = [
          {
            key: "CO2Concentration",
            label: "CO2 Concentration (PPM)",
            children: challenges?.CO2Concentration
          },
          {
            key: "airInhaled",
            label: "Air Inhaled (Litres)",
            children: challenges?.airInhaled
          },
          {
            key: "bisphenolAProduced",
            label: "Bisphenol A Produced (Tons)",
            children: challenges?.bisphenolAProduced
          },
          {
            key: "chemicalContaminantsInBody",
            label: "Chemical Contaminants In Body",
            children: challenges?.chemicalContaminantsInBody
          },
          {
            key: "chemicalProductionSales",
            label: "Chemical Production Sales (US$)",
            children: challenges?.chemicalProductionSales
          },
          {
            key: "coalMined",
            label: "Coal Mined(Tons)",
            children: challenges?.coalMined
          },
          {
            key: "coralReefsLeft",
            label: "Coral Reefs Left (%)",
            children: challenges?.coralReefsLeft
          },
          {
            key: "costOfNotActingOnClimateChange",
            label: "Cost Of Not Acting On Climate Change (US$)",
            children: challenges?.costOfNotActingOnClimateChange
          },
          {
            key: "daysLostFromMercuryToxication",
            label: "Days Lost From Mercury Toxication",
            children: challenges?.daysLostFromMercuryToxication
          },
          {
            key: "deathsFromAirPollution",
            label: "Deaths From Air Pollution",
            children: challenges?.deathsFromAirPollution
          },
          {
            key: "deathsFromDirtyWaterDiseases",
            label: "Deaths From Dirty Water Diseases",
            children: challenges?.deathsFromDirtyWaterDiseases
          },
          {
            key: "deathsFromIndoorPollution",
            label: "deaths FromI ndoor Pollution",
            children: challenges?.deathsFromIndoorPollution
          },
          {
            key: "discardedFish",
            label: "Discarded Fish (Tons)",
            children: challenges?.discardedFish
          },
          {
            key: "eWasteWorkerIncome",
            label: "E-Waste Worker Income (US$)",
            children: challenges?.eWasteWorkerIncome
          },
          {
            key: "electricityUsed",
            label: "Electricity Used (Terajoules)",
            children: challenges?.electricityUsed
          },
          {
            key: "energyUseFromMining",
            label: "Energy Use From Mining (Gigajoules)",
            children: challenges?.energyUseFromMining
          },
          {
            key: "forestsCutDown",
            label: "Forests Cut Down",
            children: challenges?.forestsCutDown
          },
          {
            key: "freshWaterUsed",
            label: "Fresh Water Used (Tons)",
            children: challenges?.freshWaterUsed
          },
          {
            key: "goldMined",
            label: "Gold Mined (Tons)",
            children: challenges?.goldMined
          },
          {
            key: "greatPacificGarbagePatch",
            label: "Great Pacific Garbage Patch (Sq Km)",
            children: challenges?.greatPacificGarbagePatch
          },
          {
            key: "groundWaterPolluted",
            label: "Ground Water Polluted (Tons)",
            children: challenges?.groundWaterPolluted
          },
          {
            key: "hazardousWasteProduced",
            label: "Hazardous Waste Produced (Tons)",
            children: challenges?.hazardousWasteProduced
          },
          {
            key: "hazardousWasteThrownOut",
            label: "Hazardous Waste Thrown Out (Tons)",
            children: challenges?.hazardousWasteThrownOut
          },
          {
            key: "healthylifeLostFromAirPollution",
            label: "Healthy life Lost From Air Pollution",
            children: challenges?.healthylifeLostFromAirPollution
          },
          {
            key: "householdWaste",
            label: "Household Waste (Tons)",
            children: challenges?.householdWaste
          },
          {
            key: "infertileMen",
            label: "Infertile Men (%)",
            children: challenges?.infertileMen
          },
          {
            key: "landAreaBeingDegraded",
            label: "Land Area Being Degraded (Sq Km)",
            children: challenges?.landAreaBeingDegraded
          },
          {
            key: "meltedIce",
            label: "Melted Ice (Tons)",
            children: challenges?.meltedIce
          },
          {
            key: "moneySpentOnFairTrade",
            label: "Money Spent On Fair Trade (US$)",
            children: challenges?.moneySpentOnFairTrade
          },
          {
            key: "newCasesOfCancer",
            label: "New Cases Of Cancer",
            children: challenges?.newCasesOfCancer
          },
          {
            key: "ozoneRecoveryETA",
            label: "Ozone Recovery ETA",
            children: challenges?.ozoneRecoveryETA
          },
          {
            key: "peopleNeedingWater",
            label: "People Needing Water",
            children: challenges?.peopleNeedingWater
          },
          {
            key: "peopleWithoutSewageSystem",
            label: "People Without Sewage System",
            children: challenges?.peopleWithoutSewageSystem
          },
          {
            key: "pesticidesUsed",
            label: "Pesticides Used",
            children: challenges?.pesticidesUsed
          },
          {
            key: "phthalatesProduced",
            label: "Phthalates Produced (Tons)",
            children: challenges?.phthalatesProduced
          },
          {
            key: "plasticBagsProduced",
            label: "Plastic Bags Produced",
            children: challenges?.plasticBagsProduced
          },
          {
            key: "plasticWasteDumpedInOcean",
            label: "Plastic Waste Dumped In Ocean",
            children: challenges?.plasticWasteDumpedInOcean
          },
          {
            key: "pvcPlasticProduced",
            label: "PVC Plastic Produced (Tons)",
            children: challenges?.pvcPlasticProduced
          },
          {
            key: "replantedForests",
            label: "Replanted Forests",
            children: challenges?.replantedForests
          },
          {
            key: "resourcesMined",
            label: "Resources Mined (Tons)",
            children: challenges?.resourcesMined
          },
          {
            key: "riseInSeaLevel",
            label: "Rise In Sea Level (cm)",
            children: challenges?.riseInSeaLevel
          },
          {
            key: "roundwoodFromManagedForests",
            label: "Roundwood From Managed Forests (Cubic Metres)",
            children: challenges?.roundwoodFromManagedForests
          },
          {
            key: "salesOfCosmeticPreservatives",
            label: "Sales Of Cosmetic Preservatives (US$)",
            children: challenges?.salesOfCosmeticPreservatives
          },
          {
            key: "solidWasteGenerated",
            label: "Solid Waste Generated (Tons)",
            children: challenges?.solidWasteGenerated
          },
          {
            key: "speciesInRiskOfExtinction",
            label: "Species In Risk Of Extinction (%)",
            children: challenges?.speciesInRiskOfExtinction
          },
          {
            key: "steelRecycled",
            label: "Steel Recycled (Tons)",
            children: challenges?.steelRecycled
          },
          {
            key: "syntheticChemicalsOnMarket",
            label: "Synthetic Chemicals On Market",
            children: challenges?.syntheticChemicalsOnMarket
          },
          {
            key: "syntheticChemicalsProduced",
            label: "Synthetic Chemicals Produced (Tons)",
            children: challenges?.syntheticChemicalsProduced
          },
          {
            key: "toxicWasteFromGoldMined",
            label: "Toxic Waste From Gold Mined (Tons)",
            children: challenges?.toxicWasteFromGoldMined
          },
          {
            key: "untilEndOfOil",
            label: "until End Of Oil",
            children: challenges?.untilEndOfOil
          },
          {
            key: "untilFishesAreConsumed",
            label: "Until Fishes Are Consumed",
            children: challenges?.untilFishesAreConsumed
          },
          {
            key: "untilFreshWaterRunsOut",
            label: "Until Fresh Water Runs Out",
            children: challenges?.untilFreshWaterRunsOut
          },
          {
            key: "untilMorePlasticThanFish",
            label: "Until More Plastic Than Fish",
            children: challenges?.untilMorePlasticThanFish
          },
          {
            key: "untilRainForestsEnd",
            label: "Until Rain Forests End",
            children: challenges?.untilRainForestsEnd
          },
          {
            key: "untilSupportSystemsEnd",
            label: "Until Support Systems End",
            children: challenges?.untilSupportSystemsEnd
          },
          {
            key: "valueOfSyntheticChemicals",
            label: "Value Of Synthetic Chemicals (US$)",
            children: challenges?.valueOfSyntheticChemicals
          },
          {
            key: "wasteDumped",
            label: "Waste Dumped (Tons)",
            children: challenges?.wasteDumped
          },
          {
            key: "wasteWaterFromSteelMininig",
            label: "Waste Water From Steel Mininig (Tons)",
            children: challenges?.wasteWaterFromSteelMininig
          },
          {
            key: "waterConsumed",
            label: "Water Consumed (Tons)",
            children: challenges?.waterConsumed
          },
          {
            key: "waterUsedToday",
            label: "Water Used Today (Litres)",
            children: challenges?.waterUsedToday
          },
          {
            key: "wildForestLeft",
            label: "Wild Forest Left (%)",
            children: challenges?.wildForestLeft
          },
          {
            key: "willRunOutOfFood",
            label: "Will Run Out Of Food",
            children: challenges?.willRunOutOfFood
          },
          {
            key: "woodProduced",
            label: "Wood Produced (Tons)",
            children: challenges?.woodProduced
          }
        ];

        return properties.map(({ key, label, children }) => renderProperty(key, label, children));
      };
      return (
        <div className="">
          <Descriptions columns={4} items={challenges ? renderProperties() : []} />
        </div>
      );
    }
  };
  const renderPages = {
    magazine: function Magazine() {
      const magazine = pages?.magazine;
      const setMagazine = (newMagazine) => {
        setPages((prevData) => {
          return { ...prevData, magazine: newMagazine };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newMagazine = [...magazine];
          const oldMedia = getFromArray(key, magazine)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newMagazine = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              magazine
            );
          } else {
            newMagazine = replaceById(key, { ...item, media: filteredMedia }, magazine);
          }
          setMagazine(newMagazine);
          payload.pages.magazine = newMagazine;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-3`}>
            {magazine && (Array.isArray(magazine) || magazine.length > 0) ? (
              magazine.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row gap-1 text-xs font-semibold text-gray-500 mb-1">
                          <p>{item.publication}</p>
                          <p>{item.edition}</p>
                        </div>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, magazine)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newMagazine = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  magazine
                                                );
                                                setMagazine(newMagazine);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newMagazine = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          magazine
                                        );
                                        setMagazine(newMagazine);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    newspaper: function Newspaper() {
      const newspaper = pages?.newspaper;
      const setNewspaper = (newPaper) => {
        setPages((prevData) => {
          return { ...prevData, newspaper: newPaper };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newPaper = [...newspaper];
          const oldMedia = getFromArray(key, newspaper)?.media || [];
          const mediaToProcess = [],
            filteredMedia = [],
            uploadedMedia = [];
          oldMedia.map((media) => {
            media.src.length > 0 &&
              media.src.filter((item) => {
                if (isImageDataUri(item)) {
                  mediaToProcess.push(media);
                } else {
                  filteredMedia.push(media);
                }
              });
          });

          for (const media of mediaToProcess) {
            for (const src of media.src) {
              const uploadable = {
                src,
                key: `dates/${id}/${generateId()}.png`
              };
              const uploaded = await upload([uploadable]);
              if (uploaded.data) {
                uploadedMedia.push({ ...media, src: uploaded.data });
              }
            }
          }

          if (uploadedMedia.length > 0) {
            newPaper = replaceById(key, { ...item, media: [...filteredMedia, ...uploadedMedia] }, newspaper);
          } else {
            newPaper = replaceById(key, { ...item, media: filteredMedia }, newspaper);
          }
          setNewspaper(newPaper);
          payload.pages.newspaper = newPaper;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {newspaper && (Array.isArray(newspaper) || newspaper.length > 0) ? (
              newspaper.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <div className="flex flex-row gap-1 text-xs font-semibold text-gray-500 mb-1">
                          <p>{formatCamelCase(item.category)}</p>
                        </div>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    shouldHaveKey={true}
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, newspaper)?.media || [];
                                                const newMedia = (
                                                  Array.isArray(temp.media) ? temp.media : [temp.media]
                                                ).map((item) => ({
                                                  publication: item.key,
                                                  src: Array.isArray(item.src) ? item.src : [item.src]
                                                }));
                                                const media = [...oldMedia, ...newMedia];
                                                const newPaper = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  newspaper
                                                );
                                                setNewspaper(newPaper);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((page, pageIndex) => {
                                return (
                                  <div key={pageIndex} className="flex-shrink-0">
                                    <div key={pageIndex} className="flex-shrink-0">
                                      {Array.isArray(page?.src) &&
                                        page?.src?.length > 0 &&
                                        page?.src?.map((image, imageIndex) => (
                                          <div key={imageIndex} className="flex flex-col gap-2">
                                            <p className="text-xs">
                                              {typeof page?.publication === "string" &&
                                                formatCamelCase(page?.publication)}
                                            </p>
                                            <ImageEditor
                                              onEdit={(value) => {
                                                const oldMedia = [...item.media];
                                                const newMedia = oldMedia.filter((item) => {
                                                  if (Array.isArray(item.src)) {
                                                    const updatedValue = filterOutArray(value, item.src);
                                                    if (updatedValue.length > 0) {
                                                      return item;
                                                    }
                                                  }
                                                });
                                                const newPaper = replaceById(
                                                  key,
                                                  { ...item, media: newMedia },
                                                  newspaper
                                                );
                                                setNewspaper(newPaper);
                                              }}
                                              src={image}
                                            />
                                          </div>
                                        ))}
                                    </div>
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex gap-4 overflow-x-auto">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((page, pageIndex) => (
                                <div key={pageIndex} className="flex-shrink-0">
                                  {Array.isArray(page?.src) &&
                                    page?.src?.length > 0 &&
                                    page?.src?.map((image, imageIndex) => (
                                      <div key={imageIndex} className="flex flex-col gap-2">
                                        <p className="text-xs">
                                          {typeof page?.publication === "string" &&
                                            formatCamelCase(page?.publication)}
                                        </p>
                                        <a target="_blank" href={image} rel="noreferrer">
                                          <ImageViewer src={image} alt={`${page?.publication}`} />
                                        </a>
                                      </div>
                                    ))}
                                </div>
                              ))
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs">No Media</p>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };
  const renderNews = {
    top: function Top() {
      const top = news?.top;
      const setTop = (newTop) => {
        setNews((prevData) => {
          return { ...prevData, top: newTop };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newTop = [...top];
          const oldMedia = getFromArray(key, top)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newTop = replaceById(key, { ...item, media: [...filteredMedia, ...uploadedMedia.data] }, top);
          } else {
            newTop = replaceById(key, { ...item, media: filteredMedia }, top);
          }
          setTop(newTop);
          payload.news.top = newTop;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {top && (Array.isArray(top) || top.length > 0) ? (
              top.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newTop = replaceById(key, { ...item, title: e.target.value }, top);
                            setTop(newTop);
                          }}
                          className="mt-1"
                          value={item.title}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newTop = replaceById(key, { ...item, snippet: e.target.value }, top);
                            setTop(newTop);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, top)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newTop = replaceById(key, { ...item, media }, top);
                                                setTop(newTop);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newTop = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          top
                                        );
                                        setTop(newTop);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    world: function World() {
      const world = news?.world;
      const setWorld = (newWorld) => {
        setNews((prevData) => {
          return { ...prevData, world: newWorld };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newWorld = [...world];
          const oldMedia = getFromArray(key, world)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newWorld = replaceById(key, { ...item, media: [...filteredMedia, ...uploadedMedia.data] }, world);
          } else {
            newWorld = replaceById(key, { ...item, media: filteredMedia }, world);
          }
          setWorld(newWorld);
          payload.news.world = newWorld;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {world && (Array.isArray(world) || world.length > 0) ? (
              world.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newWorld = replaceById(key, { ...item, title: e.target.value }, world);
                            setWorld(newWorld);
                          }}
                          className="mt-1"
                          value={item.title}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newWorld = replaceById(key, { ...item, snippet: e.target.value }, world);
                            setWorld(newWorld);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, world)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newWorld = replaceById(key, { ...item, media }, world);
                                                setWorld(newWorld);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newWorld = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          world
                                        );
                                        setWorld(newWorld);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    africa: function Africa() {
      const africa = news?.africa;
      const setAfrica = (newAfrica) => {
        setNews((prevData) => {
          return { ...prevData, africa: newAfrica };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newAfrica = [...africa];
          const oldMedia = getFromArray(key, africa)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newAfrica = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              africa
            );
          } else {
            newAfrica = replaceById(key, { ...item, media: filteredMedia }, africa);
          }
          setAfrica(newAfrica);
          payload.news.africa = newAfrica;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {africa && (Array.isArray(africa) || africa.length > 0) ? (
              africa.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newAfrica = replaceById(key, { ...item, title: e.target.value }, africa);
                            setAfrica(newAfrica);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newAfrica = replaceById(key, { ...item, snippet: e.target.value }, africa);
                            setAfrica(newAfrica);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, africa)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newAfrica = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  africa
                                                );
                                                setAfrica(newAfrica);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newAfrica = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          africa
                                        );
                                        setAfrica(newAfrica);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    americas: function Americas() {
      const americas = news?.americas;
      const setAmericas = (newAmericas) => {
        setNews((prevData) => {
          return { ...prevData, americas: newAmericas };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newAmericas = [...americas];
          const oldMedia = getFromArray(key, americas)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newAmericas = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              americas
            );
          } else {
            newAmericas = replaceById(key, { ...item, media: filteredMedia }, americas);
          }
          setAmericas(newAmericas);
          payload.news.americas = newAmericas;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {americas && (Array.isArray(americas) || americas.length > 0) ? (
              americas.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newAmericas = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              americas
                            );
                            setAmericas(newAmericas);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newAmericas = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              americas
                            );
                            setAmericas(newAmericas);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, americas)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newAmericas = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  americas
                                                );
                                                setAmericas(newAmericas);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newAmericas = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          americas
                                        );
                                        setAmericas(newAmericas);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    asiaPacific: function AsiaPacific() {
      const asiaPacific = news?.asiaPacific;
      const setAsiaPacific = (newAsiaPacific) => {
        setNews((prevData) => {
          return { ...prevData, asiaPacific: newAsiaPacific };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newAsiaPacific = [...asiaPacific];
          const oldMedia = getFromArray(key, asiaPacific)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newAsiaPacific = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              asiaPacific
            );
          } else {
            newAsiaPacific = replaceById(key, { ...item, media: filteredMedia }, asiaPacific);
          }
          setAsiaPacific(newAsiaPacific);
          payload.news.asiaPacific = newAsiaPacific;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {asiaPacific && (Array.isArray(asiaPacific) || asiaPacific.length > 0) ? (
              asiaPacific.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newAsiaPacific = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              asiaPacific
                            );
                            setAsiaPacific(newAsiaPacific);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newAsiaPacific = replaceById(
                              key,
                              { ...item, snippet: e.target.value },
                              asiaPacific
                            );
                            setAsiaPacific(newAsiaPacific);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, asiaPacific)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newAsiaPacific = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  asiaPacific
                                                );
                                                setAsiaPacific(newAsiaPacific);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newAsiaPacific = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          asiaPacific
                                        );
                                        setAsiaPacific(newAsiaPacific);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    china: function China() {
      const china = news?.china;
      const setChina = (newChina) => {
        setNews((prevData) => {
          return { ...prevData, china: newChina };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newChina = [...china];
          const oldMedia = getFromArray(key, china)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newChina = replaceById(key, { ...item, media: [...filteredMedia, ...uploadedMedia.data] }, china);
          } else {
            newChina = replaceById(key, { ...item, media: filteredMedia }, china);
          }
          setChina(newChina);
          payload.news.china = newChina;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {china && (Array.isArray(china) || china.length > 0) ? (
              china.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newChina = replaceById(key, { ...item, title: e.target.value }, china);
                            setChina(newChina);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newChina = replaceById(key, { ...item, snippet: e.target.value }, china);
                            setChina(newChina);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, china)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newChina = replaceById(key, { ...item, media }, china);
                                                setChina(newChina);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newChina = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          china
                                        );
                                        setChina(newChina);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    europe: function Europe() {
      const europe = news?.europe;
      const setEurope = (newEurope) => {
        setNews((prevData) => {
          return { ...prevData, europe: newEurope };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newEurope = [...europe];
          const oldMedia = getFromArray(key, europe)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newEurope = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              europe
            );
          } else {
            newEurope = replaceById(key, { ...item, media: filteredMedia }, europe);
          }
          setEurope(newEurope);
          payload.news.europe = newEurope;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {europe && (Array.isArray(europe) || europe.length > 0) ? (
              europe.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newEurope = replaceById(key, { ...item, title: e.target.value }, europe);
                            setEurope(newEurope);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newEurope = replaceById(key, { ...item, snippet: e.target.value }, europe);
                            setEurope(newEurope);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, europe)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newEurope = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  europe
                                                );
                                                setEurope(newEurope);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newEurope = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          europe
                                        );
                                        setEurope(newEurope);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    india: function India() {
      const india = news?.india;
      const setIndia = (newIndia) => {
        setNews((prevData) => {
          return { ...prevData, india: newIndia };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newIndia = [...india];
          const oldMedia = getFromArray(key, india)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newIndia = replaceById(key, { ...item, media: [...filteredMedia, ...uploadedMedia.data] }, india);
          } else {
            newIndia = replaceById(key, { ...item, media: filteredMedia }, india);
          }
          setIndia(newIndia);
          payload.news.india = newIndia;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {india && (Array.isArray(india) || india.length > 0) ? (
              india.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newIndia = replaceById(key, { ...item, title: e.target.value }, india);
                            setIndia(newIndia);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newIndia = replaceById(key, { ...item, snippet: e.target.value }, india);
                            setIndia(newIndia);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, india)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newIndia = replaceById(key, { ...item, media }, india);
                                                setIndia(newIndia);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newIndia = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          india
                                        );
                                        setIndia(newIndia);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    middleEast: function MiddleEast() {
      const middleEast = news?.middleEast;
      const setMiddleEast = (newMiddleEast) => {
        setNews((prevData) => {
          return { ...prevData, middleEast: newMiddleEast };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newMiddleEast = [...middleEast];
          const oldMedia = getFromArray(key, middleEast)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newMiddleEast = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              middleEast
            );
          } else {
            newMiddleEast = replaceById(key, { ...item, media: filteredMedia }, middleEast);
          }
          setMiddleEast(newMiddleEast);
          payload.news.middleEast = newMiddleEast;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {middleEast && (Array.isArray(middleEast) || middleEast.length > 0) ? (
              middleEast.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newMiddleEast = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              middleEast
                            );
                            setMiddleEast(newMiddleEast);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newMiddleEast = replaceById(
                              key,
                              { ...item, snippet: e.target.value },
                              middleEast
                            );
                            setMiddleEast(newMiddleEast);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, middleEast)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newMiddleEast = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  middleEast
                                                );
                                                setMiddleEast(newMiddleEast);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newMiddleEast = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          middleEast
                                        );
                                        setMiddleEast(newMiddleEast);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    unitedKingdom: function UnitedKingdom() {
      const unitedKingdom = news?.unitedKingdom;
      const setUnitedKingdom = (newUnitedKingdom) => {
        setNews((prevData) => {
          return { ...prevData, unitedKingdom: newUnitedKingdom };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newUnitedKingdom = [...unitedKingdom];
          const oldMedia = getFromArray(key, unitedKingdom)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newUnitedKingdom = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              unitedKingdom
            );
          } else {
            newUnitedKingdom = replaceById(key, { ...item, media: filteredMedia }, unitedKingdom);
          }
          setUnitedKingdom(newUnitedKingdom);
          payload.news.unitedKingdom = newUnitedKingdom;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {unitedKingdom && (Array.isArray(unitedKingdom) || unitedKingdom.length > 0) ? (
              unitedKingdom.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newUnitedKingdom = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              unitedKingdom
                            );
                            setUnitedKingdom(newUnitedKingdom);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newUnitedKingdom = replaceById(
                              key,
                              { ...item, snippet: e.target.value },
                              unitedKingdom
                            );
                            setUnitedKingdom(newUnitedKingdom);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia =
                                                  getFromArray(key, unitedKingdom)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newUnitedKingdom = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  unitedKingdom
                                                );
                                                setUnitedKingdom(newUnitedKingdom);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newUnitedKingdom = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          unitedKingdom
                                        );
                                        setUnitedKingdom(newUnitedKingdom);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    unitedStates: function UnitedStates() {
      const unitedStates = news?.unitedStates;
      const setUnitedStates = (newUnitedStates) => {
        setNews((prevData) => {
          return { ...prevData, unitedStates: newUnitedStates };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newUnitedStates = [...unitedStates];
          const oldMedia = getFromArray(key, unitedStates)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newUnitedStates = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              unitedStates
            );
          } else {
            newUnitedStates = replaceById(key, { ...item, media: filteredMedia }, unitedStates);
          }
          setUnitedStates(newUnitedStates);
          payload.news.unitedStates = newUnitedStates;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {unitedStates && (Array.isArray(unitedStates) || unitedStates.length > 0) ? (
              unitedStates.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newUnitedStates = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              unitedStates
                            );
                            setUnitedStates(newUnitedStates);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newUnitedStates = replaceById(
                              key,
                              { ...item, snippet: e.target.value },
                              unitedStates
                            );
                            setUnitedStates(newUnitedStates);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, unitedStates)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newUnitedStates = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  unitedStates
                                                );
                                                setUnitedStates(newUnitedStates);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newUnitedStates = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          unitedStates
                                        );
                                        setUnitedStates(newUnitedStates);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };
  const renderTrends = {
    general: function General() {
      const general = trends?.general;
      const setGeneral = (newGeneral) => {
        setTrends((prevData) => {
          return { ...prevData, general: newGeneral };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newGeneral = [...general];
          const oldMedia = getFromArray(key, general)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newGeneral = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              general
            );
          } else {
            newGeneral = replaceById(key, { ...item, media: filteredMedia }, general);
          }
          setGeneral(newGeneral);
          payload.trends.general = newGeneral;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {general && (Array.isArray(general) || general.length > 0) ? (
              general.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newGeneral = replaceById(key, { ...item, title: e.target.value }, general);
                            setGeneral(newGeneral);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newGeneral = replaceById(
                              key,
                              { ...item, snippet: e.target.value },
                              general
                            );
                            setGeneral(newGeneral);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, general)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newGeneral = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  general
                                                );
                                                setGeneral(newGeneral);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newGeneral = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          general
                                        );
                                        setGeneral(newGeneral);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    environmental: function Environmental() {
      const environmental = trends?.environmental;
      const setEnvironmental = (newEnvironmental) => {
        setTrends((prevData) => {
          return { ...prevData, environmental: newEnvironmental };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newEnvironmental = [...environmental];
          const oldMedia = getFromArray(key, environmental)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newEnvironmental = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              environmental
            );
          } else {
            newEnvironmental = replaceById(key, { ...item, media: filteredMedia }, environmental);
          }
          setEnvironmental(newEnvironmental);
          payload.trends.environmental = newEnvironmental;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {environmental && (Array.isArray(environmental) || environmental.length > 0) ? (
              environmental.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newEnvironmental = replaceById(
                              key,
                              { ...item, title: e.target.value },
                              environmental
                            );
                            setEnvironmental(newEnvironmental);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newEnvironmental = replaceById(
                              key,
                              { ...item, snippet: e.target.value },
                              environmental
                            );
                            setEnvironmental(newEnvironmental);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia =
                                                  getFromArray(key, environmental)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newEnvironmental = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  environmental
                                                );
                                                setEnvironmental(newEnvironmental);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newEnvironmental = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          environmental
                                        );
                                        setEnvironmental(newEnvironmental);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    social: function Social() {
      const social = trends?.social;
      const setSocial = (newSocial) => {
        setTrends((prevData) => {
          return { ...prevData, social: newSocial };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newSocial = [...social];
          const oldMedia = getFromArray(key, social)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newSocial = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              social
            );
          } else {
            newSocial = replaceById(key, { ...item, media: filteredMedia }, social);
          }
          setSocial(newSocial);
          payload.trends.social = newSocial;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-1`}>
            {social && (Array.isArray(social) || social.length > 0) ? (
              social.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newSocial = replaceById(key, { ...item, title: e.target.value }, social);
                            setSocial(newSocial);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Description</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          onChange={(e) => {
                            const newSocial = replaceById(key, { ...item, snippet: e.target.value }, social);
                            setSocial(newSocial);
                          }}
                          className="mt-1"
                          value={item.snippet}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.snippet}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, social)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newSocial = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  social
                                                );
                                                setSocial(newSocial);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newSocial = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          social
                                        );
                                        setSocial(newSocial);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    music: function Music() {
      const music = trends?.music;
      const setMusic = (newMusic) => {
        setTrends((prevData) => {
          return { ...prevData, music: newMusic };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newMusic = [...music];
          const oldMedia = getFromArray(key, music)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newMusic = replaceById(key, { ...item, media: [...filteredMedia, ...uploadedMedia.data] }, music);
          } else {
            newMusic = replaceById(key, { ...item, media: filteredMedia }, music);
          }
          setMusic(newMusic);
          payload.trends.music = newMusic;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-3`}>
            {music && (Array.isArray(music) || music.length > 0) ? (
              music.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Rank</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.rank}
                          onChange={(e) => {
                            const newMusic = replaceById(key, { ...item, rank: e.target.value }, music);
                            setMusic(newMusic);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.rank}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Artist</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.artist}
                          onChange={(e) => {
                            const newMusic = replaceById(key, { ...item, artist: e.target.value }, music);
                            setMusic(newMusic);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.artist}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newMusic = replaceById(key, { ...item, title: e.target.value }, music);
                            setMusic(newMusic);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Peak Position</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.peakPosition}
                          onChange={(e) => {
                            const newMusic = replaceById(
                              key,
                              { ...item, peakPosition: e.target.value },
                              music
                            );
                            setMusic(newMusic);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.peakPosition}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Position Last Week</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.positionLastWeek}
                          onChange={(e) => {
                            const newMusic = replaceById(
                              key,
                              { ...item, positionLastWeek: e.target.value },
                              music
                            );
                            setMusic(newMusic);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.positionLastWeek}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Weeks On Chart</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.weeksOnChart}
                          onChange={(e) => {
                            const newMusic = replaceById(
                              key,
                              { ...item, weeksOnChart: e.target.value },
                              music
                            );
                            setMusic(newMusic);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.weeksOnChart}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, music)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newMusic = replaceById(key, { ...item, media }, music);
                                                setMusic(newMusic);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newMusic = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          music
                                        );
                                        setMusic(newMusic);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    },
    movies: function Movies() {
      const movies = trends?.movies;
      const setMovies = (newMovies) => {
        setTrends((prevData) => {
          return { ...prevData, movies: newMovies };
        });
      };
      const save = async (key, item) => {
        const id = content.date._id;
        const payload = { _id: id, statistics, trends, news, pages };
        if (temp?.media) {
          let newMovies = [...movies];
          const oldMedia = getFromArray(key, movies)?.media || [];
          const mediaToProcess = oldMedia.filter((item) => isImageDataUri(item));
          const filteredMedia = oldMedia.filter((item) => isImageDataUri(item) === false);
          const mediaToUpload = generateUploadable(mediaToProcess, `dates/${id}/`);
          const uploadedMedia = await upload(mediaToUpload);
          if (uploadedMedia.data) {
            newMovies = replaceById(
              key,
              { ...item, media: [...filteredMedia, ...uploadedMedia.data] },
              movies
            );
          } else {
            newMovies = replaceById(key, { ...item, media: filteredMedia }, movies);
          }
          setMovies(newMovies);
          payload.trends.movies = newMovies;
        }
        const response = await update(payload);
        if (response.data) setEditingKey(null);
      };
      return (
        <div className="w-full mx-auto rounded-b-lg border-b bg-card text-card-foreground">
          <div className={`grid grid-cols-3`}>
            {movies && (Array.isArray(movies) || movies.length > 0) ? (
              movies.map((item) => {
                const key = item.id;
                const isEditing = editingKey === key;
                return (
                  <div key={key} className={`flex flex-col gap-4 p-4 border`}>
                    <div>
                      <div className="flex flex-row justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Rank</p>
                        {isEditing ? (
                          <div className="flex flex-row justify-between gap-4">
                            <div>
                              <p
                                className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                onClick={async () => {
                                  await save(key, item);
                                }}
                              >
                                Save
                              </p>
                            </div>
                            <div>
                              <p
                                className="text-xs font-semibold text-red-500 mb-1 cursor-pointer"
                                onClick={() => {
                                  resetState();
                                  setEditingKey(null);
                                }}
                              >
                                Cancel
                              </p>
                            </div>
                          </div>
                        ) : (
                          <p
                            className="text-xs font-semibold text-gray-500 mb-1 cursor-pointer"
                            onClick={() => setEditingKey(key)}
                          >
                            Edit
                          </p>
                        )}
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.rank}
                          onChange={(e) => {
                            const newMovies = replaceById(key, { ...item, rank: e.target.value }, movies);
                            setMovies(newMovies);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.rank}</div>
                      )}
                    </div>

                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Title</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.title}
                          onChange={(e) => {
                            const newMovies = replaceById(key, { ...item, title: e.target.value }, movies);
                            setMovies(newMovies);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.title}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Domestic</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.domestic}
                          onChange={(e) => {
                            const newMovies = replaceById(key, { ...item, domestic: e.target.value }, movies);
                            setMovies(newMovies);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.domestic}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Domestic Gross To Date</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.domesticGrossToDate}
                          onChange={(e) => {
                            const newMovies = replaceById(
                              key,
                              { ...item, domesticGrossToDate: e.target.value },
                              movies
                            );
                            setMovies(newMovies);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.domesticGrossToDate}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Foreign</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.foreign}
                          onChange={(e) => {
                            const newMovies = replaceById(key, { ...item, foreign: e.target.value }, movies);
                            setMovies(newMovies);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.foreign}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Foreign Gross To Date</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.foreignGrossToDate}
                          onChange={(e) => {
                            const newMovies = replaceById(
                              key,
                              { ...item, foreignGrossToDate: e.target.value },
                              movies
                            );
                            setMovies(newMovies);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.foreignGrossToDate}</div>
                      )}
                    </div>
                    <div>
                      <div className="flex justify-between items-center">
                        <p className="text-xs font-semibold text-gray-500 mb-1">Worldwide</p>
                      </div>
                      {isEditing ? (
                        <Textarea
                          className="mt-1"
                          value={item.worldwide}
                          onChange={(e) => {
                            const newMovies = replaceById(
                              key,
                              { ...item, worldwide: e.target.value },
                              movies
                            );
                            setMovies(newMovies);
                          }}
                        />
                      ) : (
                        <div className="text-sm max-h-40 overflow-auto">{item.worldwide}</div>
                      )}
                    </div>
                    <div>
                      {isEditing ? (
                        <div className="flex gap-4">
                          <div className="flex justify-center items-center">
                            <Dialog>
                              <DialogTrigger asChild>
                                <Plus className="h-10 w-10 rounded-full text-white bg-blue-500 cursor-pointer m-4 mx-8" />
                              </DialogTrigger>
                              <DialogContent className="max-w-[625px]">
                                <DialogHeader>
                                  <DialogTitle className="mb-2">Upload Media</DialogTitle>
                                  <DialogDescription>
                                    File types supported : .png, .jpg, .jepg, webp, .gif, .svg. Maximum File
                                    size : 5mb.
                                  </DialogDescription>
                                </DialogHeader>
                                <div className="flex flex-col">
                                  <ImageUploader
                                    allowMultiple={true}
                                    onFinish={(data) => {
                                      setTemp((prevData) => {
                                        return { ...prevData, media: data };
                                      });
                                    }}
                                  />
                                </div>
                                <DialogFooter>
                                  <DialogClose asChild>
                                    <div className="flex flex-row justify-between gap-4">
                                      <div>
                                        {temp?.media ? (
                                          <p
                                            className="text-xs font-semibold text-blue-500 mb-1 cursor-pointer"
                                            onClick={async () => {
                                              if (temp.media) {
                                                const oldMedia = getFromArray(key, movies)?.media || [];
                                                const newMedia = Array.isArray(temp.media)
                                                  ? temp.media
                                                  : [temp.media];
                                                const media = [...oldMedia, ...newMedia];
                                                const newMovies = replaceById(
                                                  key,
                                                  { ...item, media },
                                                  movies
                                                );
                                                setMovies(newMovies);
                                              }
                                            }}
                                          >
                                            Add
                                          </p>
                                        ) : (
                                          <p className="text-xs font-semibold text-gray-500 mb-1">Add</p>
                                        )}
                                      </div>
                                      <div>
                                        <p className="text-xs font-semibold text-red-500 mb-1 cursor-pointer">
                                          Cancel
                                        </p>
                                      </div>
                                    </div>
                                  </DialogClose>
                                </DialogFooter>
                              </DialogContent>
                            </Dialog>
                          </div>
                          <div className="flex mt-1 gap-4 overflow-x-auto justify-center items-center">
                            {Array.isArray(item.media) && item.media.length > 0 ? (
                              item.media.map((src, index) => {
                                return (
                                  <div key={index} className="flex-shrink-0">
                                    <ImageEditor
                                      onEdit={(value) => {
                                        const updatedValue = filterOutArray(value, item.media);
                                        const newMovies = replaceById(
                                          key,
                                          { ...item, media: updatedValue },
                                          movies
                                        );
                                        setMovies(newMovies);
                                      }}
                                      src={src}
                                    />
                                  </div>
                                );
                              })
                            ) : (
                              <p className="text-gray-400 mt-3 text-xs mb-2">No Media</p>
                            )}
                          </div>
                        </div>
                      ) : (
                        <div>
                          <div className="flex justify-between items-center">
                            <p className="text-xs font-semibold text-gray-500 mb-1">Media</p>
                          </div>
                          <div>
                            <div className="flex gap-4 overflow-x-auto">
                              {Array.isArray(item.media) && item.media.length > 0 ? (
                                item.media.map((image, imageIndex) => (
                                  <div key={imageIndex} className="flex-shrink-0">
                                    <a target="_blank" href={image} rel="noreferrer">
                                      <ImageViewer src={image} alt={item.title} />
                                    </a>
                                  </div>
                                ))
                              ) : (
                                <p className="text-gray-400 mt-3 text-xs">No Media</p>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="text-center m-4">
                <p>No Results</p>
              </div>
            )}
          </div>
        </div>
      );
    }
  };

  return (
    <div>
      {content && content.date ? (
        <div className="grid grid-cols-12">
          <div className="col-span-2 ">
            <Sidenav
              items={[
                {
                  label: (
                    <span className={`${navigation.includes("statistics") ? "text-black" : ""}`}>
                      Statistics
                    </span>
                  ),
                  children: [
                    {
                      key: "statistics-economy",
                      label: (
                        <span
                          className={`${
                            navigation === "statistics-economy"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("statistics-economy");
                          }}
                        >
                          Economy
                        </span>
                      )
                    },
                    {
                      key: "statistics-population",
                      label: (
                        <span
                          className={`${
                            navigation === "statistics-population"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("statistics-population");
                          }}
                        >
                          Population
                        </span>
                      )
                    },
                    {
                      key: "statistics-challenges",
                      label: (
                        <span
                          className={`${
                            navigation === "statistics-challenges"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("statistics-challenges");
                          }}
                        >
                          Challenges
                        </span>
                      )
                    }
                  ]
                },
                {
                  label: <span className={`${navigation.includes("pages") ? "text-black" : ""}`}>Pages</span>,
                  children: [
                    {
                      key: "pages-magazine",
                      label: (
                        <span
                          className={`${
                            navigation === "pages-magazine"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("pages-magazine");
                          }}
                        >
                          Magazine
                        </span>
                      )
                    },
                    {
                      key: "pages-paper",
                      label: (
                        <span
                          className={`${
                            navigation === "pages-paper"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("pages-paper");
                          }}
                        >
                          Newspaper
                        </span>
                      )
                    }
                  ]
                },
                {
                  label: <span className={`${navigation.includes("news") ? "text-black" : ""}`}>News</span>,
                  children: [
                    {
                      key: "news-top",
                      label: (
                        <span
                          className={`${
                            navigation === "news-top"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-top");
                          }}
                        >
                          Top
                        </span>
                      )
                    },
                    {
                      key: "news-world",
                      label: (
                        <span
                          className={`${
                            navigation === "news-world"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-world");
                          }}
                        >
                          World
                        </span>
                      )
                    },
                    {
                      key: "news-africa",
                      label: (
                        <span
                          className={`${
                            navigation === "news-africa"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-africa");
                          }}
                        >
                          Africa
                        </span>
                      )
                    },
                    {
                      key: "news-americas",
                      label: (
                        <span
                          className={`${
                            navigation === "news-americas"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-americas");
                          }}
                        >
                          Americas
                        </span>
                      )
                    },
                    {
                      key: "news-asiaPacific",
                      label: (
                        <span
                          className={`${
                            navigation === "news-asiaPacific"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-asiaPacific");
                          }}
                        >
                          Asia Pacific
                        </span>
                      )
                    },
                    {
                      key: "news-china",
                      label: (
                        <span
                          className={`${
                            navigation === "news-china"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-china");
                          }}
                        >
                          China
                        </span>
                      )
                    },
                    {
                      key: "news-europe",
                      label: (
                        <span
                          className={`${
                            navigation === "news-europe"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-europe");
                          }}
                        >
                          Europe
                        </span>
                      )
                    },
                    {
                      key: "news-india",
                      label: (
                        <span
                          className={`${
                            navigation === "news-india"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-india");
                          }}
                        >
                          India
                        </span>
                      )
                    },
                    {
                      key: "news-middleEast",
                      label: (
                        <span
                          className={`${
                            navigation === "news-middleEast"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-middleEast");
                          }}
                        >
                          Middle East
                        </span>
                      )
                    },
                    {
                      key: "news-unitedKingdom",
                      label: (
                        <span
                          className={`${
                            navigation === "news-unitedKingdom"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-unitedKingdom");
                          }}
                        >
                          United Kingdom
                        </span>
                      )
                    },
                    {
                      key: "news-unitedStates",
                      label: (
                        <span
                          className={`${
                            navigation === "news-unitedStates"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("news-unitedStates");
                          }}
                        >
                          United States
                        </span>
                      )
                    }
                  ]
                },
                {
                  label: (
                    <span className={`${navigation.includes("trends") ? "text-black" : ""}`}>Trends</span>
                  ),
                  children: [
                    {
                      key: "trends-general",
                      label: (
                        <span
                          className={`${
                            navigation === "trends-general"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("trends-general");
                          }}
                        >
                          General
                        </span>
                      )
                    },
                    {
                      key: "trends-social",
                      label: (
                        <span
                          className={`${
                            navigation === "trends-social"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("trends-social");
                          }}
                        >
                          Social
                        </span>
                      )
                    },
                    {
                      key: "trends-environmental",
                      label: (
                        <span
                          className={`${
                            navigation === "trends-environmental"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("trends-environmental");
                          }}
                        >
                          Environmental
                        </span>
                      )
                    },
                    {
                      key: "trends-music",
                      label: (
                        <span
                          className={`${
                            navigation === "trends-music"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("trends-music");
                          }}
                        >
                          Music
                        </span>
                      )
                    },
                    {
                      key: "trends-movies",
                      label: (
                        <span
                          className={`${
                            navigation === "trends-movies"
                              ? "cursor-pointer text-black"
                              : "cursor-pointer hover:underline"
                          }`}
                          onClick={() => {
                            setNavigation("trends-movies");
                          }}
                        >
                          Movies
                        </span>
                      )
                    }
                  ]
                }
              ]}
            />
          </div>
          <div className="col-span-10 -ml-8 max-h-392 overflow-y-auto">
            <div className="w-full mx-auto border bg-card text-card-foreground mb-2 px-6 py-3">
              <div className="flex items-center justify-between text-xs font-light text-gray-800">
                <div className="flex items-center justify-between">
                  <div>Fulfillment ID : {content._id} &nbsp; | &nbsp; </div>
                  <div>{content?.createdAt && formatDate(parseISO(content?.createdAt), "yyyy-MM-dd")}</div>
                </div>
                <div className="flex items-center justify-between">
                  <div>Date ID : {content.date._id} &nbsp; | &nbsp; </div>
                  <div>
                    {content?.date?.createdAt && formatDate(parseISO(content?.date?.createdAt), "yyyy-MM-dd")}
                  </div>
                </div>
              </div>
            </div>
            <div>{renderNavigator(navigation)}</div>
          </div>
        </div>
      ) : (
        <div className="text-center m-4">
          <p>No Results</p>
        </div>
      )}
    </div>
  );
}
